import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { createClient } from "urql";
import DashboardTitleStats from "./DashboardTitleStats";
import DashboardItems from "./DashboardItems";


const api_key = "27de416cae27059ae17eda857342bf95";
const APIURL_GNOSIS = "https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/FPPoFB7S2dcCNrRyjM5QbaMwKqRZPdbTg8ysBrwXd4SP";
const APIURL_ETHEREUM = "https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/EVjGN4mMd9h9JfGR7yLC6T2xrJf9syhjQNboFb7GzxVW";
const APIURL_RMM = "https://gateway-arbitrum.network.thegraph.com/api/" + api_key + "/subgraphs/id/9Ut97U2oMKwRucppj7fdMeZ8oFCJrUYyr8wu4hFXBn7Y";


// const APIURL_GNOSIS = "https://api.thegraph.com/subgraphs/name/realtoken-thegraph/realtoken-xdai";
// const APIURL_ETHEREUM = "https://api.thegraph.com/subgraphs/name/realtoken-thegraph/realtoken-eth";
// const APIURL_ETHEREUM_LIGHT = "https://api.thegraph.com/subgraphs/name/realtoken-thegraph/realtoken-eth-light";
// const APIURL_RMM = "https://api.thegraph.com/subgraphs/name/realtoken-thegraph/rmm-realt";
const APIURL_WHITELIST = `https://ehpst.duckdns.org/realt_rent_tracker/api/whitelist/`;
const APIURL_EHPST_HOLDER_RENT = "https://ehpst.duckdns.org/realt_rent_tracker/holder"; // + (token.uuid1 / + token.uuid2)
// const APIURL_EHPST_TOKEN_RENT = "https://ehpst.duckdns.org/realt_rent_tracker/token/"; // + (token.uuid)
const MAX_REQUEST_TOKEN = 20;

const radios = ["Single Family", "Multi Family", "Duplex", "Quadplex", "Commercial", "Mixed-Use", "SFR Portfolio", "Condominium","MFR Portfolio"];
const radios_chain = ["Ethereum", "Gnosis", "Both"];

var imgStats = "/images/stats_black.jpg";
var imgFetching = "/images/loading.gif";
var img_fleche_haut = "/images/fleche_haut.png";
var img_fleche_bas = "/images/fleche_bas.png";
var img_fleche_vide = "/images/fleche_vide.png";

// Hook
function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
}

const Dashboard = () => {
	const [data_xdai, setDataXdai] = useState([]); // This state will store the RealToken parsed data
	const [data_eth, setDataEth] = useState([]); // This state will store the RealToken parsed data
	const [data_rmm, setDataRmm] = useState([]); // This state will store the RealToken parsed data
	const [data_wl1, setDataWL1] = useState([]); // This state will store the RealToken parsed data
	const [data_wl2, setDataWL2] = useState([]); // This state will store the RealToken parsed data
	const [data_wl3, setDataWL3] = useState([]); // This state will store the RealToken parsed data
	const [data_wl4, setDataWL4] = useState([]); // This state will store the RealToken parsed data
	var data = []; // This state will store the RealToken parsed data
	var borow = []; // This state will store the RealToken parsed data
	var lend = []; // This state will store the RealToken parsed data
	const [dataAccount1, setDataAccount1] = useState([]); // This state will store the wallet1 parsed data
	const [dataAccount2, setDataAccount2] = useState([]); // This state will store the wallet2 parsed data
	const [dataAccount3, setDataAccount3] = useState([]); // This state will store the wallet3 parsed data
	const [dataAccount4, setDataAccount4] = useState([]); // This state will store the wallet3 parsed data
	const [dataBorow1, setDataBorow1] = useState([]); // This state will store the RealToken parsed data
	const [dataBorow2, setDataBorow2] = useState([]); // This state will store the RealToken parsed data
	const [dataBorow3, setDataBorow3] = useState([]); // This state will store the RealToken parsed data
	const [dataBorow4, setDataBorow4] = useState([]); // This state will store the RealToken parsed data
	const [dataLend1, setDataLend1] = useState([]); // This state will store the RealToken parsed data
	const [dataLend2, setDataLend2] = useState([]); // This state will store the RealToken parsed data
	const [dataLend3, setDataLend3] = useState([]); // This state will store the RealToken parsed data
	const [dataLend4, setDataLend4] = useState([]); // This state will store the RealToken parsed data
	const [checkboxW1, setCheckboxW1] = useState(JSON.parse(localStorage.getItem("setCheckboxW1_dashboard")) || false);
	const [checkboxW2, setCheckboxW2] = useState(JSON.parse(localStorage.getItem("setCheckboxW2_dashboard")) || false);
	const [checkboxW3, setCheckboxW3] = useState(JSON.parse(localStorage.getItem("setCheckboxW3_dashboard")) || false);

	const [checkboxWithamount, setCheckboxWithamount] = useState(JSON.parse(localStorage.getItem("checkboxWithamount_dashboard")) || false);
	const [checkboxWithoutamount, setCheckboxWithoutamount] = useState(JSON.parse(localStorage.getItem("checkboxWithoutamount_dashboard")) || false);
	const [checkboxRMM, setCheckboxRMM] = useState(JSON.parse(localStorage.getItem("setCheckboxRMM_dashboard")) || false);
	const [checkboxFullRented, setCheckboxFullRented] = useState(JSON.parse(localStorage.getItem("setCheckboxFullRented_dashboard")) || false);
	const [checkboxWhitelist, setCheckboxWhitelist] = useState(JSON.parse(localStorage.getItem("setCheckboxWhitelist_dashboard")) || false);
	const [checkboxFusion, setCheckboxFusion] = useState(JSON.parse(localStorage.getItem("setCheckboxFusion_dashboard")) || false);
	// const [checkboxFusion, setCheckboxFusion] = useState(JSON.parse(true));

	const [is_fetching, setIs_fetching] = useState(false);
	const [selectedRadioChain, setSelectedRadioChain] = useState(JSON.parse(localStorage.getItem("selectedRadioChain_dashboard")) || "");
	const [selectedRadio, setSelectedRadio] = useState(JSON.parse(localStorage.getItem("selectedRadio_dashboard")) || "");
	const [selectedTextSearch, setSelectedTextSearch] = useState(JSON.parse(localStorage.getItem("selectedTextSearch_dashboard")) || "");
	const [selectedAccount1, setSelectedAccount1] = useState(JSON.parse(localStorage.getItem("selectedAccount1_dashboard")) || "");
	const [selectedAccount2, setSelectedAccount2] = useState(JSON.parse(localStorage.getItem("selectedAccount2_dashboard")) || "");
	const [selectedAccount3, setSelectedAccount3] = useState(JSON.parse(localStorage.getItem("selectedAccount3_dashboard")) || "");
	// const [selectedAccount4, setSelectedAccount4] = useState([]);
	const [sortRentStartAsc, setSortRentStartAsc] = useState(JSON.parse(localStorage.getItem("sortRentStartAsc_dashboard")) || []);
	const [sortNbTokenAsc, setSortNbTokenAsc] = useState(JSON.parse(localStorage.getItem("sortNbTokenAsc_dashboard")) || []);
	const client_xdai = createClient({ url: APIURL_GNOSIS });
	const client_eth = createClient({ url: APIURL_ETHEREUM });
	const client_rmm = createClient({ url: APIURL_RMM });
	// if (checkboxW3) tokenStats += "/"+selectedAccount3;

	const [smallScreen, setsmallScreen] = useState(false);
	const size = useWindowSize();
	useEffect(() => {
		if (size.width < 1200) {
			setsmallScreen(true);
		} else {
			setsmallScreen(false);
		}
	}, [size.width]);

	const queryParameters = new URLSearchParams(window.location.search);
	const selectedAccount4 = queryParameters.get("holder");

	var tokenStats = APIURL_EHPST_HOLDER_RENT;
	if (checkboxW1) tokenStats += "/" + selectedAccount1;
	if (checkboxW2) tokenStats += "/" + selectedAccount2;
	if (selectedAccount4) tokenStats = APIURL_EHPST_HOLDER_RENT + "/" + selectedAccount4;

	// console.log("selectedAccount4:", selectedAccount4);

	useEffect(() => {
		if (selectedAccount1 && checkboxW1 && !selectedAccount4) {
			fetchData(selectedAccount1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (selectedAccount2 && checkboxW2 && !selectedAccount4) {
			// console.log("start read : " + selectedAccount2);
			fetchData(selectedAccount2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (selectedAccount3 && checkboxW3 && !selectedAccount4) {
			// console.log("start read : " + selectedAccount3);
			fetchData(selectedAccount3);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (selectedAccount4) {
			// console.log("start read : " + selectedAccount3);
			fetchData(selectedAccount4);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ----------------------------------
	// ---- Request on CVS Files     ----
	// ----------------------------------
	useEffect(() => {
		Papa.parse("/csv/realt_gnosis.csv", {
			header: true,
			download: true,
			delimiter: ";",
			skipEmptyLines: true,
			complete: function (results) {
				// console.log(results);
				for (var i = 0; i < results.data.length; i++) {
					results.data[i].is_xdai = true;
					results.data[i].is_eth = false;
					if (results.data[i].is_rmm === "True") results.data[i].is_rmm = true;
					if (results.data[i].is_rmm === "False") results.data[i].is_rmm = false;
					results.data[i].is_on_rmm = false;
					results.data[i].chain_logo = "/images/gnosis-logo.png";
					// console.log("xdai:", results.data[i]);
				}
				setDataXdai(results.data);
			},
		});
	}, []);
	useEffect(() => {
		Papa.parse("/csv/realt_gnosis_rmm.csv", {
			header: true,
			download: true,
			delimiter: ";",
			skipEmptyLines: true,
			complete: function (results) {
				// console.log(results);
				for (var i = 0; i < results.data.length; i++) {
					results.data[i].is_xdai = true;
					results.data[i].is_eth = false;
					results.data[i].is_rmm = true;
					results.data[i].is_on_rmm = true;
					results.data[i].chain_logo = "/images/gnosis-logo-rmm.jpg";
					// console.log("xdai:",results.data[i]);
				}
				setDataRmm(results.data);
			},
		});
	}, []);
	useEffect(() => {
		Papa.parse("/csv/realt_ethereum.csv", {
			header: true,
			download: true,
			delimiter: ";",
			skipEmptyLines: true,
			complete: function (results) {
				// console.log(results);
				for (var i = 0; i < results.data.length; i++) {
					results.data[i].is_xdai = false;
					results.data[i].is_eth = true;
					results.data[i].is_rmm = false;
					results.data[i].is_on_rmm = false;
					results.data[i].chain_logo = "/images/eth-logo.png";
					// console.log("eth:",results.data[i]);
				}
				setDataEth(results.data);
			},
		});
	}, []);

	// ----------------------------------
	// ---- Request on WhiteList API ----
	// ----------------------------------
	useEffect(() => {
		if (selectedAccount1 && checkboxW1 && !selectedAccount4) {
			fetch(APIURL_WHITELIST + selectedAccount1).then((reponse) => {
				// console.log("reponse", reponse.json());
				if (reponse.ok) reponse.json().then((body) => setDataWL1(body));
			});
		}
	}, [selectedAccount1, checkboxW1, selectedAccount4]);
	useEffect(() => {
		if (selectedAccount2 && checkboxW2 && !selectedAccount4) {
			fetch(APIURL_WHITELIST + selectedAccount2).then((reponse) => {
				// console.log("reponse", reponse.json());
				if (reponse.ok) reponse.json().then((body) => setDataWL2(body));
			});
		}
	}, [selectedAccount2, checkboxW2, selectedAccount4]);
	useEffect(() => {
		if (selectedAccount3 && checkboxW3 && !selectedAccount4) {
			fetch(APIURL_WHITELIST + selectedAccount3).then((reponse) => {
				// console.log("reponse", reponse.json());
				if (reponse.ok) reponse.json().then((body) => setDataWL3(body));
			});
		}
	}, [selectedAccount3, checkboxW3, selectedAccount4]);
	useEffect(() => {
		if (selectedAccount4) {
			fetch(APIURL_WHITELIST + selectedAccount4).then((reponse) => {
				// console.log("reponse", reponse.json());
				if (reponse.ok) reponse.json().then((body) => setDataWL4(body));
			});
		}
	}, [selectedAccount4]);

	// useEffect(() => {
	// 	if (true) {
	// 		fetch("https://pitswap-api.herokuapp.com/api/realTokens").then((reponse) => {
	// 			if (reponse.ok) {
	// 				console.log("herokuapp", reponse.json());
	// 			}
	// 		});
	// 	}
	// }, []);

	// ---------------------------------------------
	// ---- Recupération de la liste des Tokens ----
	// ---------------------------------------------
	async function fetchData(account) {
		var cpt_request = 0;
		var first = 100;
		var skip = 0;
		var nb_response = 0;
		var data_out = [];
		var data_xdai = [];
		var data_rmm = [];
		var data_borow = [];
		var data_lend = [];
		var data_eth = [];
		var response = [];
		setIs_fetching(true);
		// -------------------------
		// ---- Request on xDai ----
		// -------------------------
		if (selectedRadioChain === "Gnosis" || selectedRadioChain === "Both") {
			nb_response = 0;
			skip = 0;
			response = [];
			cpt_request = 0;
			do {
				var query =
					`{
            accounts(where: {address: "` +
					account +
					`"}) {
            balances(
              where: {amount_gt: "0"}
              first: ` +
					first +
					`
              orderBy: amount
              orderDirection: desc
              skip: ` +
					skip +
					`
            ) {
              token {
                address
              }
              amount
            }
          }
        }`;
				// console.log(query);
				response = await client_xdai.query(query).toPromise();
				// console.log("response xdai:", response);
				if (response.data) {
					if (response.data.accounts.length > 0) {
						nb_response = nb_response + response.data.accounts[0].balances.length;
						data_xdai = data_xdai.concat(response.data.accounts[0].balances);
						skip = skip + 100;
					}
				} else {
					console.log("thegraph-xdai : Pas de réponses ...");
				}
				cpt_request += 1;
				// console.log(account,"nb_response % 100:", nb_response % 100,"nb_response:", nb_response ,"cpt_request:", cpt_request);
			} while ((nb_response % 100 === 0 || nb_response % 100 === 100) && cpt_request < MAX_REQUEST_TOKEN);
		}

		// -------------------------
		// ---- Request on RMM ----
		// -------------------------
		if (selectedRadioChain === "Gnosis" || selectedRadioChain === "Both") {
			nb_response = 0;
			skip = 0;
			response = [];
			cpt_request = 0;
			query =
				`{
            user(id: "` +
				account +
				`") {
              reserves(where: {currentATokenBalance_gt: "0"}) {
                reserve {
                  underlyingAsset
                  decimals
                }
                currentATokenBalance
                liquidityRate
              }
              borrowHistory(first: 1, orderBy: timestamp, orderDirection: desc) {
                amount
                borrowRate
                borrowRateMode
              }
            }
          }`;
			// console.log(query);
			response = await client_rmm.query(query).toPromise();
			// console.log("response rmm:", response);
			if (response.data) {
				if (response.data.user) {
					data_rmm = data_rmm.concat(response.data.user.reserves);
				}
			} else {
				console.log("thegraph-rmm : Pas de réponses ...");
			}
			// console.log(account,"nb_response % 100:", nb_response % 100,"nb_response:", nb_response ,"cpt_request:", cpt_request);
		}

		// -----------------------------
		// ---- Request on Ethereum ----
		// -----------------------------
		if (selectedRadioChain === "Ethereum" || selectedRadioChain === "Both") {
			nb_response = 0;
			skip = 0;
			response = [];
			cpt_request = 0;
			do {
				query =
					`{
        accounts(where: {address: "` +
					account +
					`"}) {
          balances(
            where: {amount_gt: "0"}
            first: ` +
					first +
					`
            orderBy: amount
            orderDirection: desc
            skip: ` +
					skip +
					`
          ) {
            token {
              address
            }
            amount
          }
        }
      }`;
				// console.log(query);
				response = await client_eth.query(query).toPromise();
				// console.log("response Ethereum:", response);
				if (response.data) {
					if (response.data.accounts.length > 0) {
						nb_response = nb_response + response.data.accounts[0].balances.length;
						data_eth = data_eth.concat(response.data.accounts[0].balances);
						skip = skip + 100;
					}
				} else {
					console.log("thegraph-eth : Pas de réponses ...");
				}
				cpt_request += 1;
			} while ((nb_response % 100 === 0 || nb_response % 100 === 100) && cpt_request < MAX_REQUEST_TOKEN);
		}

		// ---------------------------------
		// ---- Regroupement des Tokens ----
		// ---------------------------------
		if (selectedRadioChain === "Gnosis" || selectedRadioChain === "Both") {
			if (data_xdai.length > 0) console.log(account, "data_xdai.length", data_xdai.length);
			for (var i = 0; i < data_xdai.length; i++) {
				data_xdai[i].is_xdai = true;
				data_xdai[i].is_eth = false;
				data_xdai[i].is_on_rmm = false;
			}
			data_out = data_out.concat(data_xdai);

			// console.log(account, "data_rmm.length", data_rmm);
			data_lend = { amount: parseFloat(0), liquidityRate: parseFloat(0) };
			// eslint-disable-next-line no-redeclare
			for (var i = 0; i < data_rmm.length; i++) {
				if (data_rmm[i].reserve.underlyingAsset === "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
					data_lend = {
						amount: parseFloat(data_rmm[i].currentATokenBalance) / 10 ** 18,
						liquidityRate: parseFloat(data_rmm[i].liquidityRate) / 10 ** 18,
					};
					console.log(account, "data_lend", data_lend);
				} else {
					data_rmm[i].is_xdai = true;
					data_rmm[i].is_eth = false;
					data_rmm[i].is_on_rmm = true;
					data_rmm[i].amount = data_rmm[i].currentATokenBalance / 10 ** 18;
					data_rmm[i].token = { address: data_rmm[i].reserve.underlyingAsset };
					// if (data_rmm[i].amount === 250) console.log("amount", data_rmm[i].amount, "address", data_rmm[i].reserve.underlyingAsset);
				}
			}
			data_rmm = data_rmm.filter((a) => a.reserve.underlyingAsset !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d"); // adress of WxDai
			console.log(account, "data_rmm.length", data_rmm.length);
			data_out = data_out.concat(data_rmm);
		}
		if (selectedRadioChain === "Ethereum" || selectedRadioChain === "Both") {
			if (data_eth.length > 0) console.log(account, "data_eth.length", data_eth.length);
			// eslint-disable-next-line no-redeclare
			for (var i = 0; i < data_eth.length; i++) {
				data_eth[i].is_xdai = false;
				data_eth[i].is_eth = true;
				data_eth[i].is_on_rmm = false;
			}
			data_out = data_out.concat(data_eth);
		}
		// console.log("FetchData",data_out);
		if (account === selectedAccount1) setDataAccount1(data_out);
		if (account === selectedAccount2) setDataAccount2(data_out);
		if (account === selectedAccount3) setDataAccount3(data_out);
		if (account === selectedAccount4) setDataAccount4(data_out);
		if (account === selectedAccount1) setDataBorow1(data_borow);
		if (account === selectedAccount2) setDataBorow2(data_borow);
		if (account === selectedAccount3) setDataBorow3(data_borow);
		if (account === selectedAccount4) setDataBorow4(data_borow);
		if (account === selectedAccount1) setDataLend1(data_lend);
		if (account === selectedAccount2) setDataLend2(data_lend);
		if (account === selectedAccount3) setDataLend3(data_lend);
		if (account === selectedAccount4) setDataLend4(data_lend);

		setIs_fetching(false);
	}

	// ------------------------------------------
	// ---- Fonctions des params de la pages ----
	// ------------------------------------------
	function setCheckbox(checked, name) {
		// console.log(name, checked);
		if (name === "checkboxW1") setCheckboxW1(checked);
		if (name === "checkboxW2") setCheckboxW2(checked);
		if (name === "checkboxW3") setCheckboxW3(checked);
		if (name === "checkboxRMM") setCheckboxRMM(checked);
		if (name === "checkboxFullRented") setCheckboxFullRented(checked);
		if (name === "checkboxWhitelist") setCheckboxWhitelist(checked);
		if (name === "checkboxFusion") setCheckboxFusion(checked);
		if (name === "checkboxWithamount") {
			if (checked) setCheckboxWithoutamount(false);
			setCheckboxWithamount(checked);
		}
		if (name === "checkboxWithoutamount") {
			if (checked) setCheckboxWithamount(false);
			setCheckboxWithoutamount(checked);
		}
		if (name === "checkboxW1" || name === "checkboxW2" || name === "checkboxW3") reload_page();
	}
	function setSelectedAccount1_onChange(name) {
		console.log('name',name)
		console.log('name',name,name.includes("0x"),name.length === 42,name.length)
		if (name.includes("0x") && name.length === 42 || name==="") {
			setSelectedAccount1(name.replace(" ", "").toLowerCase());
			if (name) reload_page();
		} else alert("Wallet address not well formated !!!");
	}
	function setSelectedAccount2_onChange(name) {
		console.log('name',name,name.includes("0x"),name.length === 42,name.length)
		if (name.includes("0x") && name.length === 42 || name==="") {
			setSelectedAccount2(name.replace(" ", "").toLowerCase());
			if (name) reload_page();
		} else alert("Wallet address not well formated !!!");
	}
	function setSelectedAccount3_onChange(name) {
		console.log('name',name,name.includes("0x"),name.length === 42,name.length)
		if (name.includes("0x") && name.length === 42 || name==="") {
			setSelectedAccount3(name.replace(" ", "").toLowerCase());
			if (name) reload_page();
		} else alert("Wallet address not well formated !!!");
	}
	function setSelectedRadioChain_function(name) {
		setSelectedRadioChain(name);
		reload_page();
	}
	function setSortRentStart() {
		setSortNbTokenAsc([]);
		if (sortRentStartAsc.length === 0) setSortRentStartAsc([true]);
		else {
			if (sortRentStartAsc[0]) setSortRentStartAsc([false]);
			else setSortRentStartAsc([true]);
		}
	}
	function setSortNbToken() {
		setSortRentStartAsc([]);
		if (sortNbTokenAsc.length === 0) setSortNbTokenAsc([true]);
		else {
			if (sortNbTokenAsc[0]) setSortNbTokenAsc([false]);
			else setSortNbTokenAsc([true]);
		}
	}
	function reload_page() {
		var reload = function () {
				window.location.reload(false);
			},
			timeout = window.setTimeout(reload, 3000);
		return function () {
			window.clearTimeout(timeout);
			timeout = window.setTimeout(reload, 2000);
		};
	}

	// ---------------------------------------------
	// ---- Sauvegarde des variable de la pages ----
	// ---------------------------------------------
	useEffect(() => {
		localStorage.setItem("selectedRadio_dashboard", JSON.stringify(selectedRadio));
	}, [selectedRadio]);
	useEffect(() => {
		localStorage.setItem("selectedRadioChain_dashboard", JSON.stringify(selectedRadioChain));
	}, [selectedRadioChain]);
	useEffect(() => {
		localStorage.setItem("setCheckboxRMM_dashboard", JSON.stringify(checkboxRMM));
	}, [checkboxRMM]);
	useEffect(() => {
		localStorage.setItem("checkboxWithamount_dashboard", JSON.stringify(checkboxWithamount));
	}, [checkboxWithamount]);
	useEffect(() => {
		localStorage.setItem("checkboxWithoutamount_dashboard", JSON.stringify(checkboxWithoutamount));
	}, [checkboxWithoutamount]);
	useEffect(() => {
		localStorage.setItem("setCheckboxFullRented_dashboard", JSON.stringify(checkboxFullRented));
	}, [checkboxFullRented]);
	useEffect(() => {
		localStorage.setItem("setCheckboxWhitelist_dashboard", JSON.stringify(checkboxWhitelist));
	}, [checkboxWhitelist]);
	useEffect(() => {
		localStorage.setItem("setCheckboxFusion_dashboard", JSON.stringify(checkboxFusion));
	}, [checkboxFusion]);
	useEffect(() => {
		localStorage.setItem("selectedTextSearch_dashboard", JSON.stringify(selectedTextSearch));
	}, [selectedTextSearch]);
	useEffect(() => {
		localStorage.setItem("selectedAccount1_dashboard", JSON.stringify(selectedAccount1.toLowerCase().replace(" ", "")));
	}, [selectedAccount1]);
	useEffect(() => {
		localStorage.setItem("selectedAccount2_dashboard", JSON.stringify(selectedAccount2.toLowerCase().replace(" ", "")));
	}, [selectedAccount2]);
	useEffect(() => {
		localStorage.setItem("selectedAccount3_dashboard", JSON.stringify(selectedAccount3.toLowerCase().replace(" ", "")));
	}, [selectedAccount3]);
	useEffect(() => {
		localStorage.setItem("setCheckboxW1_dashboard", JSON.stringify(checkboxW1));
	}, [checkboxW1]);
	useEffect(() => {
		localStorage.setItem("setCheckboxW2_dashboard", JSON.stringify(checkboxW2));
	}, [checkboxW2]);
	useEffect(() => {
		localStorage.setItem("setCheckboxW3_dashboard", JSON.stringify(checkboxW3));
	}, [checkboxW3]);
	useEffect(() => {
		localStorage.setItem("sortRentStartAsc_dashboard", JSON.stringify(sortRentStartAsc));
	}, [sortRentStartAsc]);
	useEffect(() => {
		localStorage.setItem("sortNbTokenAsc_dashboard", JSON.stringify(sortNbTokenAsc));
	}, [sortNbTokenAsc]);

	if (!selectedRadioChain) setSelectedRadioChain(radios_chain[1]);

	// ---------------------------------------------------------------------------------------------
	// ---- Vérification des Token whitelisté avec les wallets selectionnés ...                 ----
	// ---------------------------------------------------------------------------------------------

	// console.log("wl1", data_wl1);
	// console.log("wl2", data_wl2);
	// console.log("wl3", data_wl3);
	// console.log("wl4", data_wl4);
	// console.log("wl4", data_wl1.length>0 || data_wl2.length>0 || data_wl3.length>0 || data_wl4.length>0);

	if (data_xdai) {
		for (var i = 0; i < data_xdai.length; i++) {
			data_xdai[i].is_whitelist_present = false;
			if (data_wl1.length > 0 || data_wl2.length > 0 || data_wl3.length > 0 || data_wl4.length > 0) data_xdai[i].is_whitelist_present = true;

			data_xdai[i].is_whitelist = false;
			for (var j = 0; j < data_wl1.length; j++) {
				if (data_xdai[i].uuid === data_wl1[j].token) {
					data_xdai[i].is_whitelist = true;
				}
			}
			for (j = 0; j < data_wl2.length; j++) {
				if (data_xdai[i].uuid === data_wl2[j].token) {
					data_xdai[i].is_whitelist = true;
				}
			}
			for (j = 0; j < data_wl3.length; j++) {
				if (data_xdai[i].uuid === data_wl3[j].token) {
					data_xdai[i].is_whitelist = true;
				}
			}
			for (j = 0; j < data_wl4.length; j++) {
				if (data_xdai[i].uuid === data_wl4[j].token) {
					// console.log("wl4", i,j,data_xdai[i].uuid,data_wl4[j].token);
					data_xdai[i].is_whitelist = true;
				}
			}
		}
	}

	if (data_rmm) {
		for (i = 0; i < data_rmm.length; i++) {
			data_rmm[i].is_whitelist_present = false;
			if (data_wl1.length > 0 || data_wl2.length > 0 || data_wl3.length > 0 || data_wl4.length > 0) data_rmm[i].is_whitelist_present = true;

			data_rmm[i].is_whitelist = false;
		}
	}

	if (data_eth) {
		for (i = 0; i < data_eth.length; i++) {
			data_eth[i].is_whitelist_present = false;
			if (data_wl1.length > 0 || data_wl2.length > 0 || data_wl3.length > 0 || data_wl4.length > 0) data_eth[i].is_whitelist_present = true;
			data_eth[i].is_whitelist = false;
			for (j = 0; j < data_wl1.length; j++) {
				if (data_eth[i].uuid === data_wl1[j].token) {
					data_eth[i].is_whitelist = true;
				}
			}
			for (j = 0; j < data_wl2.length; j++) {
				if (data_eth[i].uuid === data_wl2[j].token) {
					data_eth[i].is_whitelist = true;
				}
			}
			for (j = 0; j < data_wl3.length; j++) {
				if (data_eth[i].uuid === data_wl3[j].token) {
					data_eth[i].is_whitelist = true;
				}
			}
			for (j = 0; j < data_wl4.length; j++) {
				if (data_eth[i].uuid === data_wl4[j].token) {
					data_eth[i].is_whitelist = true;
				}
			}
		}
	}

	// ---------------------------------------------------------------------------------------------
	// ---- Vérification des Token trouvé sur le RMM, et la liste CSV...                        ----
	// ---------------------------------------------------------------------------------------------

	for (i = 0; i < dataAccount1.length; i++) {
		var found = false;
		if (dataAccount1[i].is_on_rmm) {
			for (j = 0; j < data_rmm.length; j++) {
				if (dataAccount1[i].token.address === data_rmm[j].uuid) {
					found = true;
				}
			}
			if (!found) {
				for (j = 0; j < data_xdai.length; j++) {
					if (dataAccount1[i].token.address === data_xdai[j].uuid) {
						console.log("Token not RMM", i, dataAccount1[i].amount, data_xdai[j]);
					}
				}
			}
		}
	}

	for (i = 0; i < dataAccount2.length; i++) {
		found = false;
		if (dataAccount2[i].is_on_rmm) {
			for (j = 0; j < data_rmm.length; j++) {
				if (dataAccount2[i].token.address === data_rmm[j].uuid) {
					found = true;
				}
			}
			if (!found) {
				for (j = 0; j < data_xdai.length; j++) {
					if (dataAccount2[i].token.address === data_xdai[j].uuid) {
						console.log("Token not RMM", i, dataAccount2[i].amount, data_xdai[j]);
					}
				}
			}
		}
	}

	for (i = 0; i < dataAccount3.length; i++) {
		found = false;
		if (dataAccount3[i].is_on_rmm) {
			for (j = 0; j < data_rmm.length; j++) {
				if (dataAccount3[i].token.address === data_rmm[j].uuid) {
					found = true;
				}
			}
			if (!found) {
				for (j = 0; j < data_xdai.length; j++) {
					if (dataAccount3[i].token.address === data_xdai[j].uuid) {
						console.log("Token not RMM", i, dataAccount3[i].amount, data_xdai[j]);
					}
				}
			}
		}
	}

	for (i = 0; i < dataAccount4.length; i++) {
		found = false;
		if (dataAccount4[i].is_on_rmm) {
			for (j = 0; j < data_rmm.length; j++) {
				if (dataAccount4[i].token.address === data_rmm[j].uuid) {
					found = true;
				}
			}
			if (!found) {
				for (j = 0; j < data_xdai.length; j++) {
					if (dataAccount4[i].token.address === data_xdai[j].uuid) {
						console.log("Token not RMM", i, dataAccount4[i].amount, data_xdai[j]);
					}
				}
			}
		}
	}

	// ---------------------------------------------------------------------------------------------
	// ---- Croisement des données entre les tokens et ceux des wallets pour avoir les accounts ----
	// ---------------------------------------------------------------------------------------------

	borow = { borow_w1: parseFloat(0), borow_w2: parseFloat(0), borow_w3: parseFloat(0) };
	lend = { lend_w1: parseFloat(0), lend_w2: parseFloat(0), lend_w3: parseFloat(0) };

	// console.log("data[]:", data);
	if (data_xdai || data_eth) {
		if (data_xdai) data = data.concat(data_xdai);
		if (data_rmm && !checkboxFusion) data = data.concat(data_rmm);
		if (data_eth) data = data.concat(data_eth);
		for (i = 0; i < data.length; i++) {
			// console.log("data["+i+"]",data[i]);
			data[i].nb_token_w1 = parseFloat(0);
			data[i].nb_token_w2 = parseFloat(0);
			data[i].nb_token_w3 = parseFloat(0);
			data[i].rt_yield = parseFloat(0);
			data[i].rent_yield = parseFloat(0);
			data[i].is_token = false;
		}
		// console.log("data[]:", data);
		if (checkboxW1 && selectedAccount1 && !selectedAccount4) {
			// console.log("Nb token Wallet 1:", dataAccount1.length);
			for (i = 0; i < data.length; i++) {
				// eslint-disable-next-line no-loop-func
				var filtre_xdai = dataAccount1.filter((a) => a.token.address === data[i].uuid && a.is_xdai && !a.is_on_rmm);
				if (filtre_xdai.length > 0 && data[i].is_xdai && !data[i].is_on_rmm) {
					// console.log("filtre_xdai", filtre_xdai);
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w1 = parseFloat(filtre_xdai[0].amount);
				}
				if (checkboxFusion) {
					// eslint-disable-next-line no-loop-func
					var filtre_rmm = dataAccount1.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
					if (filtre_rmm.length > 0 && data[i].is_xdai) {
						data[i].nb_token_w1 += parseFloat(filtre_rmm[0].amount);
					}
				}
				// eslint-disable-next-line no-loop-func
				filtre_rmm = dataAccount1.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
				if (filtre_rmm.length > 0 && data[i].is_xdai && data[i].is_on_rmm) {
					// console.log("filtre_xdai", filtre_xdai);
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w1 = parseFloat(filtre_rmm[0].amount);
				}
				// eslint-disable-next-line no-loop-func
				var filtre_eth = dataAccount1.filter((a) => a.token.address === data[i].uuid && a.is_eth);
				if (filtre_eth.length > 0 && data[i].is_eth) {
					// console.log("filtre_eth", filtre_eth);
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w1 = parseFloat(filtre_eth[0].amount);
				}
			}
			borow.borow_w1 = dataBorow1.amount;
			lend.lend_w1 = dataLend1.amount;
		}
		if (checkboxW2 && selectedAccount2 && !selectedAccount4) {
			// console.log("Nb token Wallet 2:", dataAccount2);
			// eslint-disable-next-line no-redeclare
			for (var i = 0; i < data.length; i++) {
				// eslint-disable-next-line no-loop-func
				filtre_xdai = dataAccount2.filter((a) => a.token.address === data[i].uuid && a.is_xdai && !a.is_on_rmm);
				if (filtre_xdai.length > 0 && data[i].is_xdai && !data[i].is_on_rmm) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w2 = parseFloat(filtre_xdai[0].amount);
				}
				if (checkboxFusion) {
					// eslint-disable-next-line no-loop-func
					var filtre_rmm = dataAccount2.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
					if (filtre_rmm.length > 0 && data[i].is_xdai) {
						data[i].nb_token_w1 += parseFloat(filtre_rmm[0].amount);
					}
				}
				// eslint-disable-next-line no-loop-func
				filtre_rmm = dataAccount2.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
				if (filtre_rmm.length > 0 && data[i].is_xdai && data[i].is_on_rmm) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w2 = parseFloat(filtre_rmm[0].amount);
				}
				// eslint-disable-next-line no-loop-func
				filtre_eth = dataAccount2.filter((a) => a.token.address === data[i].uuid && a.is_eth);
				if (filtre_eth.length > 0 && data[i].is_eth) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w2 = parseFloat(filtre_eth[0].amount);
				}
			}
			borow.borow_w2 = dataBorow2.amount;
			lend.lend_w2 = dataLend2.amount;
		}
		if (checkboxW3 && selectedAccount3 && !selectedAccount4) {
			// console.log("Nb token Wallet 3:", dataAccount3);
			// eslint-disable-next-line no-redeclare
			for (var i = 0; i < data.length; i++) {
				// eslint-disable-next-line no-loop-func
				filtre_xdai = dataAccount3.filter((a) => a.token.address === data[i].uuid && a.is_xdai && !a.is_on_rmm);
				if (filtre_xdai.length > 0 && data[i].is_xdai && !data[i].is_on_rmm) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w3 = parseFloat(filtre_xdai[0].amount);
				}
				if (checkboxFusion) {
					// eslint-disable-next-line no-loop-func
					var filtre_rmm = dataAccount3.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
					if (filtre_rmm.length > 0 && data[i].is_xdai) {
						data[i].nb_token_w1 += parseFloat(filtre_rmm[0].amount);
					}
				}
				// eslint-disable-next-line no-loop-func
				filtre_rmm = dataAccount3.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
				if (filtre_rmm.length > 0 && data[i].is_xdai && data[i].is_on_rmm) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w3 = parseFloat(filtre_rmm[0].amount);
				}
				// eslint-disable-next-line no-loop-func
				filtre_eth = dataAccount3.filter((a) => a.token.address === data[i].uuid && a.is_eth);
				if (filtre_eth.length > 0 && data[i].is_eth) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w3 = parseFloat(filtre_eth[0].amount);
				}
			}
			borow.borow_w3 = dataBorow3.amount;
			lend.lend_w3 = dataLend3.amount;
		}
		if (selectedAccount4) {
			// console.log("Nb token Wallet 3:", dataAccount3);
			// eslint-disable-next-line no-redeclare
			for (var i = 0; i < data.length; i++) {
				// eslint-disable-next-line no-loop-func
				filtre_xdai = dataAccount4.filter((a) => a.token.address === data[i].uuid && a.is_xdai && !a.is_on_rmm);
				if (filtre_xdai.length > 0 && data[i].is_xdai && !data[i].is_on_rmm) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w1 = parseFloat(filtre_xdai[0].amount);
				}
				if (checkboxFusion) {
					// eslint-disable-next-line no-loop-func
					var filtre_rmm = dataAccount4.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
					if (filtre_rmm.length > 0 && data[i].is_xdai) {
						data[i].nb_token_w1 += parseFloat(filtre_rmm[0].amount);
					}
				}
				// eslint-disable-next-line no-loop-func
				filtre_rmm = dataAccount4.filter((a) => a.token.address === data[i].uuid && a.is_xdai && a.is_on_rmm);
				if (filtre_rmm.length > 0 && data[i].is_xdai && data[i].is_on_rmm) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w1 = parseFloat(filtre_rmm[0].amount);
				}
				// eslint-disable-next-line no-loop-func
				filtre_eth = dataAccount4.filter((a) => a.token.address === data[i].uuid && a.is_eth);
				if (filtre_eth.length > 0 && data[i].is_eth) {
					data[i].rentedUnits = parseFloat(data[i].rentedUnits);
					data[i].totalUnits = parseFloat(data[i].totalUnits);
					data[i].tokenPrice = parseFloat(data[i].tokenPrice);
					data[i].totalTokens = parseFloat(data[i].totalTokens);
					data[i].netRentDayPerToken = parseFloat(data[i].netRentDayPerToken);
					data[i].netRentMonthPerToken = parseFloat(data[i].netRentMonthPerToken);
					data[i].netRentYearPerToken = parseFloat(data[i].netRentYearPerToken);
					data[i].rt_yield = (100 * data[i].netRentYearPerToken) / data[i].tokenPrice;
					data[i].nb_token_w1 = parseFloat(filtre_eth[0].amount);
				}
			}
			borow.borow_w1 = dataBorow4.amount;
			lend.lend_w1 = dataLend4.amount;
		}
		for (i = 0; i < data.length; i++) {
			data[i].nb_token = data[i].nb_token_w1 + data[i].nb_token_w2 + data[i].nb_token_w3;
			data[i].rent_yield = (100 * parseFloat(data[i].rentedUnits)) / parseFloat(data[i].totalUnits);
			data[i].rentStartDate = data[i].rentStartDate.split(" ")[0];
			if (data[i].nb_token > 0) data[i].is_token = true;
			// console.log(data[i]);
		}
	}

	// console.log("borow:", borow);
	// console.log("lend:", lend);
	// console.log("data[]:",data);
	// console.log("sortRentStartAsc:", sortRentStartAsc.length, sortRentStartAsc, "sortNbTokenAsc", sortNbTokenAsc.length, sortNbTokenAsc);
	// console.log("size");
	// console.log("size",size,smallScreen);

	if (size.width < 1200 && !smallScreen) setsmallScreen(true);

	return (
		<div className='dashboard'>
			{is_fetching && <img height='20px' src={imgFetching} alt={"Loading of RealTokens"} />}
			{selectedRadio && <button onClick={() => setSelectedRadio("")}>Reset Type Property</button>}
			{/* {selectedTextSearch !== "" && <button onClick={() => setSelectedTextSearch("")}>Erase text Filter</button>} */}
			<div className='radio-container'>
				<ul>
					<div>
						{radios_chain.map((radio) => (
							<li id='radio'>
								<input
									type='radio'
									id={radio}
									name='radio_chain'
									checked={radio === selectedRadioChain}
									onChange={(e) => setSelectedRadioChain_function(e.target.id)}
								/>
								<label htmlFor={radio}>{radio}</label>
							</li>
						))}
					</div>
					<div>
						{!selectedAccount4 && (
							<li id='checkbox'>
								<input type='checkbox' name='checkboxW1' checked={checkboxW1} onChange={(e) => setCheckbox(e.target.checked, e.target.name)} />
								<input
									type='text-adr'
									name='selectedAccount1'
									value={selectedAccount1.toLowerCase()}
									placeholder='Wallet n°1 ?'
									onFocus={(e) => e.target.select()}
									onChange={(e) => setSelectedAccount1_onChange(e.target.value)}
								/>
							</li>
						)}
						{!selectedAccount4 && (
							<li id='checkbox'>
								<input type='checkbox' name='checkboxW2' checked={checkboxW2} onChange={(e) => setCheckbox(e.target.checked, e.target.name)} />
								<input
									type='text-adr'
									name='selectedAccount2'
									value={selectedAccount2.toLowerCase()}
									placeholder='Wallet n°2 ?'
									onFocus={(e) => e.target.select()}
									onChange={(e) => setSelectedAccount2_onChange(e.target.value)}
								/>
							</li>
						)}
						{!selectedAccount4 && (
							<li id='checkbox'>
								<input type='checkbox' name='checkboxW3' checked={checkboxW3} onChange={(e) => setCheckbox(e.target.checked, e.target.name)} />
								<input
									type='text-adr'
									name='selectedAccount3'
									value={selectedAccount3.toLowerCase()}
									placeholder='Wallet n°3 ?'
									onFocus={(e) => e.target.select()}
									onChange={(e) => setSelectedAccount3_onChange(e.target.value)}
								/>
							</li>
						)}
						{selectedAccount4 && (
							<li id='checkbox'>
								<label htmlFor='selectedAccount4'>View account of Holder : </label>
							</li>
						)}
						{selectedAccount4 && (
							<li id='checkbox'>
								<input
									type='text-adr'
									name='selectedAccount4'
									value={selectedAccount4.toLowerCase()}
									placeholder='Wallet n°4 ?'
									onFocus={(e) => e.target.select()}
								/>
							</li>
						)}
						{smallScreen && (
							<div display='flex' text-align='center'>
								<li id='checkbox'>
									<label htmlFor='selectedTextSearch'>Filter by Token name :</label>
								</li>
								<li id='text'>
									<input
										type='search'
										name='selectedTextSearch'
										value={selectedTextSearch}
										placeholder='Token name ?'
										onFocus={(e) => e.target.select()}
										onChange={(e) => setSelectedTextSearch(e.target.value)}
									/>
								</li>
							</div>
						)}
					</div>
					{(checkboxW1 || checkboxW2 || selectedAccount4) && (
						<div>
							<a href={tokenStats} target='_blank' rel='noopener noreferrer'>
								<img height={smallScreen ? "45px" : "60px"} src={imgStats} alt={"RealT rent traker"} />
							</a>
						</div>
					)}
					<div>
						{radios.map((radio) => (
							<li id='radio'>
								<input type='radio' id={radio} name='radio' checked={radio === selectedRadio} onChange={(e) => setSelectedRadio(e.target.id)} />
								<label htmlFor={radio}>{radio}</label>
							</li>
						))}
					</div>
					<div>
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxWithamount'
								name='checkboxWithamount'
								checked={checkboxWithamount}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxWithamount'>With Amount</label>
						</li>
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxWithoutamount'
								name='checkboxWithoutamount'
								checked={checkboxWithoutamount}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxWithoutamount'>Without Amount</label>
						</li>
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxRMM'
								name='checkboxRMM'
								checked={checkboxRMM}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxRMM'>RMM</label>
						</li>
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxFullRented'
								name='checkboxFullRented'
								checked={checkboxFullRented}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxFullRented'>Not full Rented</label>
						</li>
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxWhitelist'
								name='checkboxWhitelist'
								checked={checkboxWhitelist}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxWhitelist'>Whitelist</label>
						</li>
						
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxFusion'
								name='checkboxFusion'
								checked={checkboxFusion}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxFusion'>Fusion</label>
						</li>
					</div>

					{!smallScreen && (
						<div>
							<li id='text'>
								<label htmlFor='selectedTextSearch'>Filter by Token name :</label>
							</li>
							<li id='text'>
								<input
									type='search'
									name='selectedTextSearch'
									value={selectedTextSearch}
									placeholder='Token name ?'
									onFocus={(e) => e.target.select()}
									onChange={(e) => setSelectedTextSearch(e.target.value)}
								/>
							</li>
						</div>
					)}
				</ul>
			</div>
			<div className='table-container'>
				<DashboardTitleStats tokens={data} borowed={borow} lended={lend} checkboxW4={selectedAccount4 ? true : false} />
			</div>
			<br />
			<div className='table-container'>
				<table>
					<thead>
						<tr>
							{/* <th>N°</th> */}
							<th>Nb</th>
							<th>BlockChain</th>
							{!smallScreen && <th>Picture</th>}
							<th>RealToken Name</th>
							<th>Price</th>
							<th>APY</th>
							<th>Stat</th>
							<th>Rent/week</th>
							<th>Rent/month</th>
							<th>Rent/year</th>
							<th>Token Value</th>
							<th id='link' onClick={() => setSortNbToken()}>
								Nb Token{" "}
								<img
									height='12px'
									// cursor='pointer'
									// onClick={() => setSortNbToken()}
									src={sortNbTokenAsc.length === 0 ? img_fleche_vide : sortNbTokenAsc[0] ? img_fleche_bas : img_fleche_haut}
									alt={"Trier les colonnes"}
								/>
							</th>
							{!smallScreen && <th>Total</th>}
							{!smallScreen && <th>Type</th>}
							{!smallScreen && <th>Rented</th>}
							<th id='link' onClick={() => setSortRentStart()}>
								Rent Start Date{" "}
								<img
									height='12px'
									src={sortRentStartAsc.length === 0 ? img_fleche_vide : sortRentStartAsc[0] ? img_fleche_bas : img_fleche_haut}
									alt={"Trier les colonnes"}
								/>
							</th>
							<th>YAM</th>
						</tr>
					</thead>
					<tbody>
						{data
							.filter((a) => (selectedRadioChain === radios_chain[0] ? a.is_eth : true))
							.filter((a) => (selectedRadioChain === radios_chain[1] ? a.is_xdai : true))
							.filter((a) => (checkboxWithamount ? a.nb_token > 0 : true))
							.filter((a) => (checkboxWithoutamount ? a.nb_token === 0 : true))
							.filter((a) => (checkboxWhitelist ? a.is_whitelist : true))
							.filter((a) => (selectedTextSearch ? a.shortName.toLowerCase().includes(selectedTextSearch.toLowerCase()) : true))
							.filter((a) => (checkboxRMM ? a.is_rmm : true))
							.filter((a) => (selectedRadio && selectedRadio === "Single Family" ? a.propertyType === "1" : true))
							.filter((a) => (selectedRadio && selectedRadio === "Multi Family" ? a.propertyType === "2" : true))
							.filter((a) => (selectedRadio && selectedRadio === "Duplex" ? a.propertyType === "3" : true))
							.filter((a) => (selectedRadio && selectedRadio === "Condominium" ? a.propertyType === "4" : true))
							.filter((a) => (selectedRadio && selectedRadio === "Mixed-Use" ? a.propertyType === "6" : true))
							.filter((a) => (selectedRadio && selectedRadio === "Quadplex" ? a.propertyType === "8" : true))
							.filter((a) => (selectedRadio && selectedRadio === "Commercial" ? a.propertyType === "9" : true))
							.filter((a) => (selectedRadio && selectedRadio === "SFR Portfolio" ? a.propertyType === "10" : true))
							.filter((a) => (selectedRadio && selectedRadio === "MFR Portfolio" ? a.propertyType === "11" : true))
							.filter((a) =>
								checkboxFullRented ? parseFloat(a.rentedUnits / a.totalUnits) < 1.0 || parseFloat(a.netRentYearPerToken) === 0.0 : true
							)
							.sort((a, b) =>
								sortRentStartAsc.length === 0
									? sortNbTokenAsc.length === 0
										? false
										: sortNbTokenAsc[0]
										? parseFloat(a.nb_token) - parseFloat(b.nb_token)
										: -parseFloat(a.nb_token) + parseFloat(b.nb_token)
									: sortRentStartAsc[0]
									? a.rentStartDate.localeCompare(b.rentStartDate)
									: b.rentStartDate.localeCompare(a.rentStartDate)
							)
							.map((value, index) => (
								<DashboardItems key={index} token={value} smallScreen={smallScreen} num={index + 1} />
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Dashboard;
