import React from "react";

const SwapTokenItems = (props) => {
	// console.log("props",props);

	var imgCoin = "/images/usd.png";
	var imgYAM = "/images/caddie-bleu.png";
	// var imgYAM_dst = "/images/caddie-bleu.png";
	const tokenYAM = "https://ehpst.duckdns.org/realt_rent_tracker/secondary_market/" + props.token.uuid;

	props.token.tokenPrice = parseFloat(props.token.tokenPrice);
	props.token.totalTokens = parseFloat(props.token.totalTokens);
	props.token.totalUnits = parseFloat(props.token.totalUnits);
	props.token.rentedUnits = parseFloat(props.token.rentedUnits);
	props.token.netRentDayPerToken = parseFloat(props.token.netRentDayPerToken);
	props.token.netRentMonthPerToken = parseFloat(props.token.netRentMonthPerToken);
	props.token.netRentYearPerToken = parseFloat(props.token.netRentYearPerToken);
	props.token.rt_yield = (100 * props.token.netRentYearPerToken) / props.token.tokenPrice;

	if (props.token.is_whitelist && props.token.is_whitelist_present) imgYAM = "/images/caddie-vert.png";
	if (!props.token.is_whitelist && props.token.is_whitelist_present) imgYAM = "/images/caddie-rouge.png";

	const is_Whitlist = props.token.is_whitelist && props.token.is_whitelist_present;

	// if (props.token.uuid === "0xf7412e264fa85ae5e79ac3a4b64ce4669e32b98f")
	// console.log("imgYAM", imgYAM,props.token.is_whitelist,props.token.is_whitelist_present);
	// if (props.token.nb_token>0)

	var nb_decimal=0;
	var nb_token = props.token.nb_token.toFixed(nb_decimal);
	while (props.token.nb_token.toString() !== props.token.nb_token.toFixed(nb_decimal) && nb_decimal<6){
		//console.log("nb_token", token.nb_token.toString(), token.nb_token.toFixed(nb_decimal));
		nb_decimal++;
		nb_token = props.token.nb_token.toFixed(nb_decimal);
	}
	if (props.token.nb_token === 0.0) nb_token = "-";

	var infobulleText = "RealToken not selectable : \n";
	var infobulleWarning = false;
	// if (!props.token.nb_token > 0) {
	// 	infobulleText += "   - No amount of token in Wallet From !!!\n";
	// 	infobulleWarning = true;
	// }
	if (!is_Whitlist > 0) {
		infobulleText += "   - Wallet To make offer not Whitelist !!!\n";
		infobulleWarning = true;
	}
	if (!infobulleWarning) infobulleText = "RealToken selectable";

	// console.log("token",props.token);

	return (
		<tr>
			<td>
				<label title={infobulleText} color={infobulleWarning ? "red" : "green"}>
					<input
						type='radio'
						id={props.token.uuid}
						name={props.token.uuid}
						disabled={infobulleWarning}
						checked={props.token.checkboxCheked}
						onChange={(e) =>
							!infobulleWarning ? props.tokenToSend(e.target.checked, e.target.name, props.token.nb_token) : (e.target.checked = !e.target.checked)
						}
					/>
				</label>
			</td>
			<td>
				<a href={props.token.marketplaceLink} target='_blank' rel='noopener noreferrer'>
					{props.token.shortName}
				</a>
			</td>
			<td>{props.token.rt_yield.toFixed(2)}%</td>
			<td>
				{props.token.nb_token === 0.0 ? "-" : (props.token.tokenPrice * props.token.nb_token).toFixed(2)}
				{props.token.nb_token === 0.0 ? "" : " "}
				{props.token.nb_token === 0.0 ? "" : <img height='15px' src={imgCoin} alt={props.token.currency} />}
			</td>
			<td>{nb_token}</td>
			{!props.smallScreen && <td>{props.token.rentStartDate}</td>}
			<td>
				<a href={tokenYAM} target='_blank' rel='noopener noreferrer'>
					<img height='20px' src={imgYAM} alt={"RealT rent traker"} />
				</a>
			</td>
		</tr>
	);
};

export default SwapTokenItems;
