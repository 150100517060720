import React from "react";

const TokenHelp = (props) => {
	return (
		<div className='table-container-help'>
			<h1>Select a RealToken</h1>
			<h2>With this function you can made a Purchase or Sale Offer</h2>
			<h3 id='center'>You have just to select the RealToken first, and define the kind of offer (radio box on the right)</h3>
			<br />
			<table className='table-container-help'>
				<tbody>
					<tr>
						<td colSpan='2'>
							<h2>Advise for define SALES offer</h2>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>1)</h3>
						</td>
						<td>
							<h3>Define the Price for the realToken you want to sell</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>2)</h3>
						</td>
						<td>
							<h3>Define the Amount for the realToken you want to sell</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>Before send</h3>
						</td>
						<td>
							<h3>There is a control of the quantity in your Metamask wallet for the selected RealToken. You can't sell more RealToken than you have !</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center-red'>Warning</h3>
						</td>
						<td>
						<h3 id="red">You can't have many offer with the same realToken because the contrat allowance is the same for each one.</h3>
						<h3>This app advice you if you have an offer for the same realToken and give you a link to remove or modify this one !</h3>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<table className='table-container-help'>
				<tbody>
					<tr>
						<td colSpan='2'>
							<h2>Advise for define PURCHASES offer</h2>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>1)</h3>
						</td>
						<td>
							<h3>Define the Price for the realToken you want to buy</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>2)</h3>
						</td>
						<td>
							<h3>There is no allowance, your amount is set to 0</h3>
							<h3>There is an allowance, your amount is calculate when the price change</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>Before send</h3>
						</td>
						<td>
							<h3>
								There is a control of the quantity in your Metamask wallet for the selected Token (USDC, WXDAI, ...)
							</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>Tips</h3>
						</td>
						<td>
							<h3>This app show you the token allowance you have define to buy realTokens. The allowance is the token amount (USDC for exemple) you autorise to use in exchange of RealToken</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center-red'>Warning</h3>
						</td>
						<td>
							<h3 id='red'>If you change the amount for this offer, the amount change for all the others PURCHASES offers !</h3>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
		</div>
	);
};

export default TokenHelp;
