import React, { useEffect } from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import SwapcatList from "../components/swapcat/SwapcatList";

const TITLE = "Pit'Swap - Buy RealToken";

const SwapcatBuy = () => {

  useEffect(() => {
    document.title = TITLE;
 }, []);
 
  return (
    <div>
      <Logo web3={false}/>
      <Navigation  />
      <SwapcatList buy_offers={true} sell_offers={false} />
    </div>
  );
};

export default SwapcatBuy;
