import React, { useEffect } from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";

const TITLE = "Pit'Swap - Community link";

const About = () => {

  useEffect(() => {
    document.title = TITLE;
 }, []);

  return (
    <div>
      <Logo web3={false} />
      <Navigation />
      <div className='dashboard'>
        <br />
        <h1 color='black'>&#129321; Liens vers les sites communautaires &#129321;</h1>
        <br />
        <p>
          <a href='https://www.cryptalloc.com/realtlab/index.php?MODL=LINK' target='_blank' rel='noopener noreferrer'>
            sites communautaires
          </a>
        </p>
        <br />
      </div>
      <br />
    </div>
  );
};

export default About;
