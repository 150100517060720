import React from "react";

// const propertyType = ["Single Family", "Multi Family", "Duplex", "Quadplex", "Commercial", "Mixed-Use"];
const propertyType = {
	1: "Single Family",
	2: "Multi Family",
	3: "Duplex",
	4: "Text_04",
	5: "Text_05",
	6: "Mixed-Use",
	7: "Text_07",
	8: "Quadplex",
	9: "Commercial",
	10: "SFR Portfolio",
	11: "Text_11",
	12: "Text_12",
};
const TokenView = (props) => {
	// const tokenStats = "https://ehpst.duckdns.org/realt_rent_tracker/token/" + props.token.uuid;
	// const tokenYAM = "https://ehpst.duckdns.org/realt_rent_tracker/secondary_market/" + props.token.uuid;

	var tokenAdress = "https://gnosisscan.io/address/" + props.token.uuid;
	if (props.token.is_eth) tokenAdress = "https://etherscan.io/token/" + props.token.uuid;
	// var imgCoin = "/images/usd.png";
	var imgYAM = "/images/caddie-bleu.png";

	props.token.tokenPrice = parseFloat(props.token.tokenPrice);
	props.token.totalTokens = parseFloat(props.token.totalTokens);
	props.token.totalUnits = parseFloat(props.token.totalUnits);
	props.token.rentedUnits = parseFloat(props.token.rentedUnits);
	props.token.netRentDayPerToken = parseFloat(props.token.netRentDayPerToken);
	props.token.netRentMonthPerToken = parseFloat(props.token.netRentMonthPerToken);
	props.token.netRentYearPerToken = parseFloat(props.token.netRentYearPerToken);
	props.token.rt_yield = (100 * props.token.netRentYearPerToken) / props.token.tokenPrice;

	if (props.token.is_whitelist && props.token.is_whitelist_present) imgYAM = "/images/caddie-vert.png";
	if (!props.token.is_whitelist && props.token.is_whitelist_present) imgYAM = "/images/caddie-rouge.png";

	// console.log("token", props.token);

	return (
		<div>
			<div>
				<h1>Selected RealToken</h1>
			</div>
			<table>
				<tbody>
					<tr>
						<td width={props.smallScreen ? "125px" : "250px"}>
							<img width='100%' src={props.token.imageLink} alt={props.token.shortName} />
						</td>
						<td>
							<ul>
								<li>
									<table>
										<tbody>
											<tr>
												<td>
													<a href={tokenAdress} target='_blank' rel='noopener noreferrer'>
														<img height='35px' src={props.token.chain_logo} alt={props.token.chain_logo} />
													</a>
												</td>
												<td>
													<a href={props.token.marketplaceLink} target='_blank' rel='noopener noreferrer'>
														<h2>{props.token.shortName}</h2>
													</a>
												</td>
												<td>
													<h2 id='yield'>{props.token.rt_yield.toFixed(2)}%</h2>
												</td>
											</tr>
										</tbody>
									</table>
									<table>
										<tbody>
											<tr>
												<td>
													<h3>Full Name</h3>
												</td>
												<td>
													<h3>{props.token.fullName}</h3>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Symbol</h3>
												</td>
												<td>
													<h3>{props.token.symbol}</h3>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Type</h3>
												</td>
												<td>
													<h3>{propertyType[props.token.propertyType]}</h3>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Tenants</h3>
												</td>
												<td>
													<h3>
														{props.token.rentedUnits} / {props.token.totalUnits}
													</h3>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Nb Token</h3>
												</td>
												<td>
													<h3>
														{props.token.nb_token} / {props.token.totalTokens} ( to have {parseInt(1 + props.token.nb_token)} token(s): buy{" "}
														{ parseInt(1 + props.token.nb_token) - parseFloat(props.token.nb_token).toFixed(6)} )
													</h3>
												</td>
											</tr>
											<tr>
												<td>
													<h3>Rent Start</h3>
												</td>
												<td>
													<h3>{props.token.rentStartDate}</h3>
												</td>
											</tr>
										</tbody>
									</table>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody>
					<tr>
						<td>
							<h3 id='center'>Price</h3>
						</td>
						<td>
							<h3 id='center'>Rent/Day</h3>
						</td>
						<td>
							<h3 id='center'>Rent/Week</h3>
						</td>
						<td>
							<h3 id='center'>Rent/Month</h3>
						</td>
						<td>
							<h3 id='center'>Rent/Year</h3>
						</td>
					</tr>
					<tr>
						<td>
							<h3 id='center'>
								{props.token.tokenPrice} {props.token.currency}
							</h3>
						</td>
						<td>
							<h3 id='center'>
								{props.token.netRentDayPerToken.toFixed(2)} {props.token.currency}
							</h3>
						</td>
						<td>
							<h3 id='center'>
								{(props.token.netRentDayPerToken * 7).toFixed(2)} {props.token.currency}
							</h3>
						</td>
						<td>
							<h3 id='center'>
								{props.token.netRentMonthPerToken.toFixed(2)} {props.token.currency}
							</h3>
						</td>
						<td>
							<h3 id='center'>
								{props.token.netRentYearPerToken.toFixed(2)} {props.token.currency}
							</h3>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default TokenView;

/*

currency
: 
"USD"
is_on_rmm
: 
false
is_rmm
: 


lastUpdate
: 
"{'date': '2023-02-03 21:39:08.000000', 'timezone_type': 3, 'timezone': 'UTC'}"


*/
