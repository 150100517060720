import React, { useEffect } from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import Dashboard from "../components/dashboard/Dashboard";

const TITLE = "Pit'Swap - Dashboard";

const Home = () => {

  useEffect(() => {
    document.title = TITLE;
 }, []);
 
  // this.Home.push();
  return (
    <div>
      <Logo web3={false}/>
      <Navigation  />
      <Dashboard />
    </div>
  );
};

export default Home;
