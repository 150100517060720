import React, { useEffect } from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import TokenUpdates from "../components/dashboard/TokenUpdates";

const TITLE = "Pit'Swap - RealTokens Update";

export default function RealTokenUpdate() {
	useEffect(() => {
		document.title = TITLE;
	}, []);

	// this.Home.push();
	return (
		<div>
			<Logo web3={false} />
			<Navigation />
			<TokenUpdates />
		</div>
	);
}
