import React from "react";

const SwapcatTitle = (props) => {
	// const [checkboxRMM, setCheckboxRMM] = useState(JSON.parse(localStorage.getItem("setCheckboxRMM")) || false);
	// const [checkboxVendeur, setCheckboxVendeur] = useState(JSON.parse(localStorage.getItem("setCheckboxVendeur")) || false);

	// console.log("vendeur=" + vendeur);

	return (
		<thead>
			<tr>
				<th>N°</th>
				<th>N° Offer</th>
				<th>Token</th>
				<th>Offer Price</th>
				<th>Offer Amount</th>
				<th>Offer Yield</th>
				<th>Ratio Price</th>
				<th>RealToken Name</th>
				<th>RealT Price</th>
				<th>RealT Yield</th>
				{props.vendeur && <th>Offer holder</th>}
				{!props.vendeur && <th>last update</th>}
				<th>WL</th>
				{props.iscol_my_offer && <th>MS</th>}
			</tr>
		</thead>
	);
};

export default SwapcatTitle;
