import React, { useState } from "react";
import { ethers } from "ethers";
// import ReactGA from "react-ga";

// var imgSEND = "/images/sendMessage.png";

const Logo = (props) => {
	const [errMessage, setErrMessage] = useState("");
	const [defaultAccount, setDefaultAccount] = useState("");
	const [userBalance, setUserBalance] = useState("");
	const adminAccount = "0x565980d400dc6d37e74baedaecb071f166f99352";

	// eslint-disable-next-line no-unused-vars
	const getadminAccount = ({ web3 = false }) => {
		if (defaultAccount === adminAccount) {
			return true;
		} else {
			return false;
		}
	};
	const connectWallet = () => {
		if (window.ethereum) {
			window.ethereum.request({ method: "eth_requestAccounts" }).then((result) => {
				if (defaultAccount !== result[0]) {
					// console.log(result[0]);
					accountChanged([result[0]]);
				}
			});
		} else {
			setErrMessage("Install Metamask please !!!");
		}
	};

	const accountChanged = (accountName) => {
		// console.log(accountName[0]);
		setDefaultAccount(accountName[0]);
		getUserBalance(accountName[0]);
	};

	const getUserBalance = (accountAddress) => {
		window.ethereum
			.request({
				method: "eth_getBalance",
				params: [String(accountAddress), "latest"],
			})
			.then((balance) => {
				setUserBalance(balance / 10 ** 18);
			});
	};

	const sendMeCoffe = async () => {
		console.log("connecting Metamask ...");
		const provider = window.ethereum;
		if (!provider) {
			alert("Please Install Matamask on your browser !!!");
			return;
		}

		console.log("Network chainId:", window.ethereum.chainId, "on Metamask:");
		if (!(window.ethereum.chainId === "0x64")) {
			alert("Please Select the right Network for Metamask, your have not selected the Gnosis Network.");
			return;
		}

		const accounts = await provider.request({ method: "eth_requestAccounts" });
		const account = accounts[0];
		console.log("account:", account);

		var balance = await provider.request({ method: "eth_getBalance", params: [account, "latest"] });
		balance = parseInt(balance, 16) / 10 ** 18;
		console.log("balance:", balance);
		if (balance < 1.001) {
			alert("I mean you don't have enough xDai to send me a coffe, only " + balance + " xDai !!!");
			return;
		}

		try {
			const provider_eth = new ethers.providers.Web3Provider(window.ethereum);
			const signer_eth = provider_eth.getSigner();

			const tx = await signer_eth.sendTransaction({
				to: adminAccount,
				value: ethers.utils.parseEther("1.0"),
			});
			console.log(tx);
		} catch (error) {
			console.log(error.message);
		}
	};

	// ReactGA.pageview(window.location.pathname);

	const pitsBI = "/logo-pitsbi2.png";

	return (
		<div className='logo'>
			{/* <div className="logo-metamask"></div> */}
			<div className='logo-app'>
				<img src='/logo192.png' alt='logo react' />
				<div>
					<h3>Pit'Swap - RealToken Dashbord</h3>
					<h4>(Version 1.5.12)</h4>
				</div>
			</div>
			<div className='logo-metamask'>
				{props.web3 && !defaultAccount && <button onClick={() => connectWallet()}>Connecter</button>}
				{/* {defaultAccount && web3 && (
          <button onClick={() => connectWallet()}>Deconnecter</button>
        )} */}
				{errMessage && props.web3 && <p>{errMessage}</p>}
				{defaultAccount && props.web3 && (
					<h3>{defaultAccount.substring(0, 4) + "..." + defaultAccount.substring(defaultAccount.length - 4, defaultAccount.length)}</h3>
				)}
				{userBalance && props.web3 && <h4>{userBalance.toFixed(6)}</h4>}
			</div>

			<div className='logo-metamask'>
				<button onClick={() => sendMeCoffe()}>Send a Coffe</button>
			</div>

			<div className='logo-metamask'>
				<div>
					<div>
						<a href={"https://realt.pitsbi.io"} target='_blank' rel='noopener noreferrer'>
							<img height='32px' src={pitsBI} alt={"New Website"} />
						</a>
					</div>
					<a href={"https://realt.pitsbi.io"} target='_blank' rel='noopener noreferrer'>
						{"Visit my new Website"}
					</a>
				</div>
				{/* <button onClick={() => sendMeCoffe()}>Visit Pit's BI, my new Website</button> */}
			</div>
		</div>
	);
};

export default Logo;
