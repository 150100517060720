import React from "react";

const DashboardItems = ({ token, num, smallScreen = false }) => {
	const tokenStats = "https://ehpst.duckdns.org/realt_rent_tracker/token/" + token.uuid;
	const tokenYAM = "https://ehpst.duckdns.org/realt_rent_tracker/secondary_market/" + token.uuid;

	var tokenAdress = "https://gnosisscan.io/address/" + token.uuid;
	if (token.is_eth) tokenAdress = "https://etherscan.io/token/" + token.uuid;
	const propertyType = {
		1: "Single Family",
		2: "Multi Family",
		3: "Duplex",
		4: "Condominium",
		5: "Text_05",
		6: "Mixed-Use",
		7: "Text_07",
		8: "Quadplex",
		9: "Commercial",
		10: "SFR Portfolio",
		11: "MFR Portfolio",
		12: "Text_12",
		13: "Text_13",
		14: "Text_14",
		15: "Text_15",
	};

	var imgCoin = "/images/usd.png";
	var imgStats = "/images/stats.jpg";
	var imgYAM = "/images/caddie-bleu.png";

	token.tokenPrice = parseFloat(token.tokenPrice);
	token.totalTokens = parseFloat(token.totalTokens);
	token.totalUnits = parseFloat(token.totalUnits);
	token.rentedUnits = parseFloat(token.rentedUnits);
	token.netRentDayPerToken = parseFloat(token.netRentDayPerToken);
	token.netRentMonthPerToken = parseFloat(token.netRentMonthPerToken);
	token.netRentYearPerToken = parseFloat(token.netRentYearPerToken);
	token.rt_yield = (100 * token.netRentYearPerToken) / token.tokenPrice;

	if (token.is_whitelist && token.is_whitelist_present) imgYAM = "/images/caddie-vert.png";
	if (!token.is_whitelist && token.is_whitelist_present) imgYAM = "/images/caddie-rouge.png";

	var nb_decimal = 0;
	var nb_token = token.nb_token.toFixed(nb_decimal);
	while (token.nb_token.toString() !== token.nb_token.toFixed(nb_decimal) && nb_decimal < 6) {
		//console.log("nb_token", token.nb_token.toString(), token.nb_token.toFixed(nb_decimal));
		nb_decimal++;
		nb_token = token.nb_token.toFixed(nb_decimal);
	}
	if (token.nb_token === 0.0) nb_token = "-";

	// if (token.uuid === "0xf7412e264fa85ae5e79ac3a4b64ce4669e32b98f")
	// console.log("imgYAM", imgYAM,token.is_whitelist,token.is_whitelist_present);
	// if (token.nb_token>0)

	return (
		<tr>
			<td>{num}</td>
			<td>
				<a href={tokenAdress} target='_blank' rel='noopener noreferrer'>
					<img height='35px' src={token.chain_logo} alt={token.chain_logo} />
				</a>
			</td>
			{!smallScreen && (
				<td>
					<img height='40px' src={token.imageLink} alt={token.shortName} />
				</td>
			)}
			<td>
				<a href={token.marketplaceLink} target='_blank' rel='noopener noreferrer'>
					{token.shortName}
				</a>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={token.currency} />
					</div>
					<div>{token.tokenPrice.toFixed(2)}</div>
				</div>
			</td>
			<td>{token.rt_yield.toFixed(2)}%</td>
			<td>
				<a href={tokenStats} target='_blank' rel='noopener noreferrer'>
					<img height='20px' src={imgStats} alt={"RealT rent traker"} />
				</a>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={token.currency} />
					</div>
					<div>{token.nb_token === 0.0 ? (7 * token.netRentDayPerToken).toFixed(2) : (7 * token.netRentDayPerToken * token.nb_token).toFixed(2)}</div>
				</div>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={token.currency} />
					</div>
					<div>{token.nb_token === 0.0 ? token.netRentMonthPerToken.toFixed(2) : (token.netRentMonthPerToken * token.nb_token).toFixed(2)}</div>
				</div>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={token.currency} />
					</div>
					<div>{token.nb_token === 0.0 ? token.netRentYearPerToken.toFixed(2) : (token.netRentYearPerToken * token.nb_token).toFixed(2)}</div>
				</div>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={token.currency} />
					</div>
					<div>{token.nb_token === 0.0 ? "-" : (token.tokenPrice * token.nb_token).toFixed(2)}</div>
				</div>
				{/* {token.nb_token === 0.0 ? "" : " "}
				{token.nb_token === 0.0 ? "" : <img height='16px' src={imgCoin} alt={token.currency} />} */}
			</td>
			<td>{nb_token}</td>

			{!smallScreen && <td>{token.totalTokens}</td>}
			{!smallScreen && <td>{propertyType[token.propertyType]}</td>}
			{!smallScreen && <td>{token.rentedUnits + "/" + token.totalUnits + " (" + token.rent_yield.toFixed(0) + "%)"}</td>}
			<td>{token.rentStartDate}</td>
			<td>
				<a href={tokenYAM} target='_blank' rel='noopener noreferrer'>
					<img height='20px' src={imgYAM} alt={"RealT rent traker"} />
				</a>
			</td>
		</tr>
	);
};

export default DashboardItems;
