import React from "react";

const imgCoin = "/images/usd.png";
const imgYAM_default = "/images/caddie-bleu.png";
const imgMS_buy = "/images/buy.png";
const imgMS_parametre = "/images/parametre.png";

const SwapcatOffer = (props) => {
	// props definition :
	// offer       = offert to show
	// num         = num order of the offer
	// vendeur     = Checkbox to show seller adress is checked
	// sell_offers = the offer is a sell offer
	// amount      = token_amount for the offer (DELETE props)

	// console.log("props:", props.offer.realtMarketplaceLink);

	var tokenYAM = "https://ehpst.duckdns.org/realt_rent_tracker/secondary_market/" + props.offer.token_to_sell;
	if (props.offer.is_buy === "True") tokenYAM = "https://ehpst.duckdns.org/realt_rent_tracker/secondary_market/" + props.offer.token_to_buy;

	var imgNew = "/images/new transparant.png";
	var imgContract = "/images/swapcat.png";
	var contract = "SwapCat";
	var adrOffer = "https://swap.cat/?oid=" + props.offer.id_offer + "&chain=100";
	if (props.offer.contract === "YAM") {
		imgContract = "/images/yam.png";
		contract = "YAM - RealT";
		adrOffer = "https://yam.realtoken.network/offer/" + props.offer.id_offer;
	}

	var token_to_buy = parseFloat(props.offer.token_value);
	var token_to_buy_usd = parseFloat(props.offer.token_value_usd);
	var token_amount = parseFloat(props.offer.token_amount);
	var realtTokenPrice = parseFloat(props.offer.realtTokenPrice);
	var realtRatioPrice = parseFloat((100 * token_to_buy_usd) / realtTokenPrice);
	var realtNetRentYearPerToken = parseFloat(props.offer.realtNetRentYearPerToken);
	var link_makeoffer = "/make_offer";

	// props.offer.timsync.split("T")[1].split(".")[0]
	const created_date = new Date(props.offer.creation_date);
	const update_date = new Date(props.offer.timsync);
	var utc_date = new Date();
	const is_new_offer = (utc_date - created_date) / 1000 / 60 / 60 < 12 ? true : false;
	var minutes_updated = (utc_date - update_date) / 1000 / 60 + utc_date.getTimezoneOffset();
	var minutes_updated_string = parseInt(minutes_updated / 60 / 24) + " day(s)";
	if (minutes_updated / 60 < 24) minutes_updated_string = parseInt(minutes_updated / 60) + " hour(s)";
	if (minutes_updated < 60) minutes_updated_string = parseInt(minutes_updated) + " minut(s)";
	if (minutes_updated < 1) minutes_updated_string = parseInt(minutes_updated * 60) + " second(s)";

	// if (minutes_updated < 60)
	// console.log("update time:", props.offer.id_offer, minutes_updated, minutes_updated_string,update_date.toLocaleString(),utc_date.toLocaleString(),utc_date.getTimezoneOffset());

	if (props.my_offer && props.offer.contract === "SC") {
		if (props.sell_offers) {
			link_makeoffer += "?numoffer=" + props.offer.id_offer + "&typeoffer=Sale";
		} else {
			link_makeoffer += "?numoffer=" + props.offer.id_offer + "&typeoffer=Purchase";
		}
	} else {
		link_makeoffer += "?numoffer=" + props.offer.id_offer + "&typeoffer=Buy";
	}

	var nb_decimal = 0;
	var nb_token = token_amount.toFixed(nb_decimal);
	while (token_amount.toString() !== token_amount.toFixed(nb_decimal) && nb_decimal < 6) {
		nb_decimal++;
		nb_token = token_amount.toFixed(nb_decimal);
	}
	if (token_amount === 0.0) nb_token = "-";
	if (token_amount < 0.01) nb_token = token_amount.toFixed(18);

	var sc_yield = (100 * realtNetRentYearPerToken) / token_to_buy_usd;
	var rt_yield = (100 * realtNetRentYearPerToken) / realtTokenPrice;

	var imgYAM = "/images/caddie-bleu.png";
	if (props.offer.is_whitelist && props.offer.is_whitelist_present) imgYAM = "/images/caddie-vert.png";
	if (!props.offer.is_whitelist && props.offer.is_whitelist_present) imgYAM = "/images/caddie-rouge.png";

	// console.log("offer.realtTokenPrice_usd", offer.imgToken);

	return (
		<tr>
			<td>{props.num}</td>
			<td>
				<div className='cellsView'>
					{is_new_offer && (
						<div>
							<img height='20px' src={imgNew} alt={contract} />{" "}
						</div>
					)}
					<div>
						<img height='20px' src={imgContract} alt={contract} />{" "}
					</div>
					<div>
						<a href={adrOffer} target='_blank' rel='noopener noreferrer'>
							{props.offer.id_offer}
						</a>
					</div>
				</div>
			</td>
			<td>
				<img height='15px' src={props.offer.imgToken} alt={props.offer.coinShortName} />
			</td>
			<td>
				{token_to_buy.toFixed(2)}
				{token_to_buy === token_to_buy_usd ? "" : " {"}
				{token_to_buy === token_to_buy_usd ? "" : parseFloat(token_to_buy_usd).toFixed(2) + " "}
				{token_to_buy === token_to_buy_usd ? "" : <img height='15px' src={imgCoin} alt={props.offer.realtCurrency} />}
				{token_to_buy === token_to_buy_usd ? "" : "}"}
			</td>
			<td>{nb_token}</td>
			<td>{sc_yield.toFixed(2)}%</td>
			<td>{realtRatioPrice.toFixed(1)}%</td>
			<td>
				<label title={props.offer.realtFullName} color={"green"}>
					<a href={props.offer.realtMarketplaceLink} target='_blank' rel='noopener noreferrer'>
						{props.offer.realtShortName}
					</a>
				</label>
			</td>
			<td>
				{realtTokenPrice.toFixed(2)} <img height='15px' src={imgCoin} alt={props.offer.realtCurrency} />
			</td>
			<td>{rt_yield.toFixed(2)}%</td>
			{props.vendeur && (
				<td>
					<a href={"/?holder=" + props.offer.holder_address.toLowerCase()} target='_blank' rel='noopener noreferrer'>
						{props.offer.holder_address.toLowerCase()}
					</a>
				</td>
			)}
			{/* props.offer.timsync.split("T")[1].split(".")[0] */}
			{!props.vendeur && <td id='09'>{minutes_updated_string}</td>}
			<td>
				<a href={tokenYAM} target='_blank' rel='noopener noreferrer'>
					<img height='20px' src={props.sell_offers ? imgYAM : imgYAM_default} alt={"RealT rent traker"} />
				</a>
			</td>
			{props.iscol_my_offer && (
				<td>
					<a
						href={link_makeoffer}
						//  target='_blank'
						//   rel='noopener noreferrer'
					>
						{/* <a href={link_makeoffer} > */}
						<img height='20px' src={props.my_offer ? imgMS_parametre : imgMS_buy} alt={"PitSwap secondary market"} />
					</a>
				</td>
			)}
		</tr>
	);
};

export default SwapcatOffer;
