import React from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import About from "./pages/About";
import Home from "./pages/Home";
import SwapcatBuy from "./pages/SwapcatBuy";
import SwapToken from "./pages/SwapToken";
import SwapcatSell from "./pages/SwapcatSell";
import MakeOffer from "./pages/MakeOffer";
import Cryptalloc from "./pages/Cryptalloc";
import RealTokenUpdate from "./pages/RealTokenUpdate";


const App = () => {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/:holder" element={<Home />} />
          <Route path="/home/:holder" element={<Home />} />
          <Route path="/token_updates" element={<RealTokenUpdate />} />
          <Route path="/swap_token" element={<SwapToken />} />
          <Route path="/achat_token" element={<SwapcatBuy />} />
          <Route path="/vente_token" element={<SwapcatSell />} />
          <Route path="/make_offer" element={<MakeOffer />} />
          <Route path="/about" element={<About />} />
          <Route path="/cryptalloc" element={<Cryptalloc />} />
          <Route path="*" element={<Home />} />
        </Routes>
    </BrowserRouter>
  );
};

export default App;
