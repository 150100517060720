import React, { useState } from "react";

var imgCoin = "/images/usd.png";

const DashboardTitleStatsItems = ({ wallet }) => {
	return (
		<tr>
			<td>{wallet.nom}</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={"USD"} />
					</div>
					<div>{wallet.invest.toFixed(2).toLocaleString()}</div>
				</div>
			</td>
			<td>{wallet.apy.toFixed(2)}%</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={"USD"} />
					</div>
					<div>{wallet.rentDay.toFixed(2)}</div>
				</div>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={"USD"} />
					</div>
					<div>{(7 * wallet.rentDay).toFixed(2)}</div>
				</div>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={"USD"} />
					</div>
					<div>{wallet.rentMont.toFixed(2)}</div>
				</div>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={"USD"} />
					</div>
					<div>{wallet.rentYear.toFixed(2)}</div>
				</div>
			</td>
			<td>
				<div className='cellsView'>
					<div>
						<img height='16px' src={imgCoin} alt={"USD"} />
					</div>
					<div>{wallet.lend.toFixed(2)}</div>
				</div>
			</td>
			{/* <td>
        {wallet.borow.toFixed(2)} <img height='15px' src={imgCoin} alt={"USD"} />
      </td> */}
			<td>{wallet.nbToken.toFixed(2)}</td>
			<td>
				{wallet.rentedTotal > 0
					? wallet.rented + "/" + wallet.rentedTotal + " (" + parseFloat((100 * wallet.rented) / wallet.rentedTotal).toFixed(1) + "%)"
					: wallet.rented + "/" + wallet.rentedTotal + " (--%)"}
			</td>
		</tr>
	);
};

const DashboardTitleStats = ({ tokens = [], borowed = [], lended = [], checkboxW4 = false }) => {
	const [selectedRadioChain] = useState(JSON.parse(localStorage.getItem("selectedRadioChain_dashboard")) || "");
	const [checkboxW1] = useState(JSON.parse(localStorage.getItem("setCheckboxW1_dashboard")) || false);
	const [checkboxW2] = useState(JSON.parse(localStorage.getItem("setCheckboxW2_dashboard")) || false);
	const [checkboxW3] = useState(JSON.parse(localStorage.getItem("setCheckboxW3_dashboard")) || false);
	var wallets = [];
	var is_xdai = false;
	var is_eth = false;

	// var tokens_filter = tokens.filter((a) => a.nb_token > 0);
	// console.log("tokens_filter:", tokens_filter);
	// console.log("borowed:", borowed);
	// console.log("lended:", lended);

	if (selectedRadioChain === "Ethereum" || selectedRadioChain === "Both") is_eth = true;
	if (selectedRadioChain === "Gnosis" || selectedRadioChain === "Both") is_xdai = true;

	if (checkboxW1 || checkboxW4) {
		var wallet = {
			nom: "Wallet n°1",
			invest: parseFloat(0.0),
			apy: parseFloat(0.0),
			rentDay: parseFloat(0.0),
			rentMont: parseFloat(0.0),
			rentYear: parseFloat(0.0),
			nbToken: parseFloat(0.0),
			rented: parseInt(0),
			rentedTotal: parseInt(0),
			borow: parseFloat(borowed.borow_w1),
			lend: parseFloat(lended.lend_w1),
		};
		// console.log(wallet.nom);
		for (var i = 0; i < tokens.length; i++) {
			if (tokens[i].nb_token_w1 > 0 && (tokens[i].is_eth === is_eth || tokens[i].is_xdai === is_xdai)) {
				wallet.invest += tokens[i].nb_token_w1 * tokens[i].tokenPrice;
				wallet.rentDay += tokens[i].nb_token_w1 * tokens[i].netRentDayPerToken;
				wallet.rentMont += tokens[i].nb_token_w1 * tokens[i].netRentMonthPerToken;
				wallet.rentYear += tokens[i].nb_token_w1 * tokens[i].netRentYearPerToken;
				wallet.nbToken += tokens[i].nb_token_w1;
				wallet.rented += parseInt(tokens[i].rentedUnits);
				wallet.rentedTotal += parseInt(tokens[i].totalUnits);
				wallet.apy = (100 * wallet.rentYear) / wallet.invest;
				// console.log("W1 tokens:",wallet.rented,wallet.rentedTotal);
			}
		}
		wallets = wallets.concat(wallet);
	}
	if (checkboxW2 && !checkboxW4) {
		// eslint-disable-next-line no-redeclare
		var wallet = {
			nom: "Wallet n°2",
			invest: parseFloat(0.0),
			apy: parseFloat(0.0),
			rentDay: parseFloat(0.0),
			rentMont: parseFloat(0.0),
			rentYear: parseFloat(0.0),
			nbToken: parseFloat(0.0),
			rented: parseFloat(0.0),
			rentedTotal: parseFloat(0.0),
			borow: parseFloat(borowed.borow_w2),
			lend: parseFloat(lended.lend_w2),
		};
		// console.log(wallet.nom);
		// eslint-disable-next-line no-redeclare
		for (var i = 0; i < tokens.length; i++) {
			if (tokens[i].nb_token_w2 > 0 && (tokens[i].is_eth === is_eth || tokens[i].is_xdai === is_xdai)) {
				wallet.invest += tokens[i].nb_token_w2 * tokens[i].tokenPrice;
				wallet.rentDay += tokens[i].nb_token_w2 * tokens[i].netRentDayPerToken;
				wallet.rentMont += tokens[i].nb_token_w2 * tokens[i].netRentMonthPerToken;
				wallet.rentYear += tokens[i].nb_token_w2 * tokens[i].netRentYearPerToken;
				wallet.nbToken += tokens[i].nb_token_w2;
				wallet.rented += parseInt(tokens[i].rentedUnits);
				wallet.rentedTotal += parseInt(tokens[i].totalUnits);
				wallet.apy = (100 * wallet.rentYear) / wallet.invest;
				// console.log("W2 tokens:",tokens[i]);
			}
		}
		wallets = wallets.concat(wallet);
	}
	if (checkboxW3 && !checkboxW4) {
		// eslint-disable-next-line no-redeclare
		var wallet = {
			nom: "Wallet n°3",
			invest: parseFloat(0.0),
			apy: parseFloat(0.0),
			rentDay: parseFloat(0.0),
			rentMont: parseFloat(0.0),
			rentYear: parseFloat(0.0),
			nbToken: parseFloat(0.0),
			rented: parseFloat(0.0),
			rentedTotal: parseFloat(0.0),
			borow: parseFloat(borowed.borow_w3),
			lend: parseFloat(lended.lend_w3),
		};
		// console.log(wallet.nom);
		// eslint-disable-next-line no-redeclare
		for (var i = 0; i < tokens.length; i++) {
			if (tokens[i].nb_token_w3 > 0 && (tokens[i].is_eth === is_eth || tokens[i].is_xdai === is_xdai)) {
				wallet.invest += tokens[i].nb_token_w3 * tokens[i].tokenPrice;
				wallet.rentDay += tokens[i].nb_token_w3 * tokens[i].netRentDayPerToken;
				wallet.rentMont += tokens[i].nb_token_w3 * tokens[i].netRentMonthPerToken;
				wallet.rentYear += tokens[i].nb_token_w3 * tokens[i].netRentYearPerToken;
				wallet.nbToken += tokens[i].nb_token_w3;
				wallet.rented += parseInt(tokens[i].rentedUnits);
				wallet.rentedTotal += parseInt(tokens[i].totalUnits);
				wallet.apy = (100 * wallet.rentYear) / wallet.invest;
				// console.log("W3 tokens:",tokens[i]);
			}
		}
		wallets = wallets.concat(wallet);
	}
	if (checkboxW1 || checkboxW2 || checkboxW3 || checkboxW4) {
		var wallet_total = {
			nom: "TOTAL",
			invest: parseFloat(0.0),
			apy: parseFloat(0.0),
			rentDay: parseFloat(0.0),
			rentMont: parseFloat(0.0),
			rentYear: parseFloat(0.0),
			nbToken: parseFloat(0.0),
			rented: parseFloat(0.0),
			rentedTotal: parseFloat(0.0),
			borow: parseFloat(borowed.borow_w1 + borowed.borow_w2 + borowed.borow_w3),
			lend: parseFloat(lended.lend_w1 + lended.lend_w2 + lended.lend_w3),
		};
		// eslint-disable-next-line no-redeclare
		for (var i = 0; i < wallets.length; i++) {
			wallet_total.invest += wallets[i].invest;
			wallet_total.rentDay += wallets[i].rentDay;
			wallet_total.rentMont += wallets[i].rentMont;
			wallet_total.rentYear += wallets[i].rentYear;
			wallet_total.nbToken += wallets[i].nbToken;
			wallet_total.rented += wallets[i].rented;
			wallet_total.rentedTotal += wallets[i].rentedTotal;
			wallet_total.apy = (100 * wallet_total.rentYear) / wallet_total.invest;
		}
		wallets = wallets.concat(wallet_total);
	}
	// console.log("wallets:", wallets);

	return (
		<table>
			<thead>
				<tr>
					<th>Wallet</th>
					<th>Wallet Value</th>
					<th>APY</th>
					<th>Rent/day</th>
					<th>Rent/week</th>
					<th>Rent/month</th>
					<th>Rent/year</th>
					<th>Lend</th>
					{/* <th>Borrow</th> */}
					<th>Nb Token</th>
					<th>Rented</th>
				</tr>
			</thead>
			{wallets.map((value) => (
				<tbody>
					<DashboardTitleStatsItems wallet={value} />
				</tbody>
			))}
		</table>
	);
};

export default DashboardTitleStats;
