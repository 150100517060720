import React, { useEffect } from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";

const TITLE = "Pit'Swap - About";

const About = () => {
	useEffect(() => {
		document.title = TITLE;
	}, []);

	return (
		<div>
			<Logo web3={false} />
			<Navigation />
			<div className='dashboard'>
				<br />
				<h1 color='black'>Pit'Swap - Dashboard et Viewer des offres SwapCat</h1>
				<br />
				<p>&#129321; Je fais de l'auto-apprentissage de React JS depuis Nöel 2022 &#129321;</p>
				<p>Je souhaitais vous partager l'avancement de mes compétance au travers de cette mise à jour du design de mon ancien site.</p>
				<br />
				<p>PS: Les barres de menu sont bien remplie, l'utilisation sur smartphone sera améliorée ultétieurement !!!</p>
				<br />
				<br />
				<h1 color='black'>Historique des versions de l'application</h1>
				<br />
				<h1>Menu de la barre de recherche (Achats et Ventes)</h1>
				<img height='50px' src='/images/barre recherche.PNG' alt='barre recherche.PNG' />
				<p>1) Filtrage de 10 à 250 offres SwapCat (250 = Toutes les offres)</p>
				<p>2) Filtrage de 0 à 1& token au moins disponible dans les offres SwapCat (si 0, affichage de toutes les offres avec 18 décimales)</p>
				<p>3) Filtrage de 1% à 15% de Yield RealT minimum dans les offres SwapCat</p>
				<p>4) Filtrage de 1% à 30% d'écart de prix entre Swapcat et RealT</p>
				<p>5&6) Filtrage par Token d'achat (USDC ou WxDai)</p>
				<p>7) Filtrage des offres SwapCat avec un Token RealT sur le RMM</p>
				<p>8) Affichage de l'adresse du vendeur à la place de la date de dernière mise à jour de l'offre</p>
				<p>9) Filtrage par texte de saisie sur le Nom du Token RealT ou l'adresse du holder</p>
				<p>&#129321;Il n'est pas nécessaire de cocher la case d'adresse du holder pour filtrer vos offres &#129321;</p>
				<br />
				<br />
				<h1>Version 1.5.12</h1>
				<p>- Remplacement du token CHSB par le token BORG (dédicace à Draazzz) </p>
				<br />
				<h1>Version 1.5.11</h1>
				<p>- Correction d'anomalies sur la page Make Offer (Contrôle de Allowance avant création d'une offre) </p>
				<p>- Suppression du Token CHSB </p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.10</h1>
				<p>- Correction du bon nombre de token à transferer entre 2 wallets lorsque plusieurs sont sélectionné. </p>
				<br />
				<h1>Version 1.5.9</h1>
				<p>- Ajout d'un bouton AVG Yield pour faire des recherches sur le marché secondaire avec le Yield Moyen du Token</p>
				<p> a) Après une réévaluation du token, son Yield moyen sera plus haut que l'actuel (cela indique le futur potentiel après augmentation de loyer)</p>
				<p> b) Après une vacances locatives du token, son Yield moyen sera en baisse jusqu'au retour du locataire</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.8</h1>
				<p>- Evolution de l'API pour la mise à jour des offres SC et YAM en temps réel (lecture blockchain)</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.7</h1>
				<p>- Ajout du nombre de ligne sur la page Dashboard pour le calcul du Nb de propriété possédé</p>
				<p>ou compter le nombre de propriété impacté par les filtres comme 'Not full Rented' par exemple (Merci à Stéphane pour l'idée)</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.6</h1>
				<p>- Correction de regression sur les 2 pages de offres du marché secondaire (Purchases et Sales)</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.5</h1>
				<p>- Ajout d'un critère de recherche dans les offres Yam et SwapCat sur le nom long du token (filtrage par ville)</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.4</h1>
				<p>- Ajout d'un critère de recherche sur la Key dans la page Update</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.3</h1>
				<p>- Ajout d'un tableau listant les modifications sur les realTokens</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.2</h1>
				<p>- Refresh du nombre des offres toutes les minutes dans la barres de navigation</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.1</h1>
				<p>- Mise en place d'une API backend pour plus de nouveauté future</p>
				<p>- Ajout des offres YAM sur le marché secondaire (refresh ~6/12 heures)</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.5.0</h1>
				<p>- Passage du site sur Netlify</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.4.5</h1>
				<p>- Correction du filtre sur les écart de prix RealT et offre Swapcat</p>
				<p>- Suppression du filtre sur les écarts de prix RealT dans les offres d'achat</p>
				<p>- Correction de la fonction de mise en vente lorsque le nb de token est identique à celui du wallet</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.4.4 (News: Secondary Market)</h1>
				<p>- Ajout de la colonne Ratio Price dans les offres de ventes</p>
				<p>- Ajout de la fusion de token Gnosis et RMM sur le Dashboard</p>
				<p>- Ajout du bouton "Send me a coffe", pour m'envoyer 1 xDai (très demandé par la communauté)</p>
				<p>(Test en cours sur le Secondary Market, en attente de vos retours)</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.4.3 (News: Secondary Market)</h1>
				<p>
					- Ajout de la recherche d'offre active (achat ou vente) pour le holder quand il en cré une nouvelle pour lui éviter d'en avoir plusieurs par
					realToken
				</p>
				<p>(Test en cours sur le Secondary Market, en attente de vos retours)</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.4.2 (test en cours Secondary Market)</h1>
				<p>- Ajout d'une page pour la creation d'offre d'achat ou de vente SwapCat (pour 4 Tokens : USDC, WXDAI, CHSB, WETH)</p>
				<p>- Modification de vos offres depuis les pages Purchases & Sales</p>
				<p>- Pas encore la page pour les achat (prochaine version)</p>
				<p>(Test en cours sur le Secondary Market, en attente de vos retours)</p>
				<p>- Agrandissement de la zone de click pour le swap de token</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.4.1</h1>
				<p>- Ajout du Whitelist dans les offres de ventes swapcat en entrant votre Wallet de paiement</p>
				<p>- Ajout du filtrage des offres de ventes swapcat que vous pouvez acheter, RealToken Whitelist</p>
				<p>- Mise en forme des offres d'achat (Purchase) pour les utilisateurs moins expérimenté (calcul prix/token comme les ventes)</p>
				<p>- Amélioration de l'interface utilisateur pour les swaps pour une meilleure utilisation</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.4.0</h1>
				<p>- Ajout de la fonction permettant de transférer les tokens d'un wallet vers un autre</p>
				<p>- J'avais pris cet engagement lors du CC98, minute 19</p>
				<p>- Indication du whitelist sur les 2 wallets par les 2 caddies vert</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.3.6</h1>
				<p>- Conversion en USD des offres swapcat non USDC ou WXDAI (avec API CoinGecko)</p>
				<p>- Remplacement du Yield Maximum par un ratio sur l'écart de prix dans l'offre</p>
				<p>- Petite amélioration sur l'interface, checkboxs et champs textes de recherche</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.3.5 (test en cours WHITELIST)</h1>
				<p>- Ajout du parametre holder dans l'url du dashboard pour visualiser le contenu d'un wallet</p>
				<p> (exemple : http://marclanders.duckdns.org/?holder=0x...)</p>
				<p>- Remplacement dans les offres swapcat des bouton Radio par des Chexkbox</p>
				<p>- Ajout du lien vers le wallet du seller dans les offres swapcat</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.3.3 (test en cours WHITLIST)</h1>
				<p>- Intégration des tokens Whitelist de vos Wallets, si vous constatez des erreurs, ecrivez moi sur Télégram</p>
				<p> 1) Le caddie vert indique que vous pouvez acheter sur le marché secondaire, cliquez pour voir les offres</p>
				<p> 2) Le caddie rouge indique vous ne pourrez pas acheter sur le marché secondaire (Ex token RMM)</p>
				<p> 3) Le caddie bleu indique vos information de whitelist n'existe pas encore, revenez consulter dans quelques minutes</p>
				<p>- Intégration des tokens RMM avec le nombre de Token déposé et prise en compte dans le calcul global</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.3.2 (test en cours RMM)</h1>
				<p>- Ajout des tokens manquant sur le RMM, surement la source des erreurs de compte sur les wallets</p>
				<p>- Ajout d'un caddie en bout de ligne pour vous renvoyer vers les offres YAM et SwapCat de @elphs</p>
				<p>- Ajout d'une traces dans la consoles pour que vous puissiez m'informer des autres token manquant sur le RMM</p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.3.1 (test en cours RMM)</h1>
				<p>- Ajout des tokens mis en colatéral sur le RMM </p>
				<p>- Ajout des liquidités prétés ou empruntés sur le RMM par Wallet </p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.3.1</h1>
				<p>- Correction de l'anomalie des tokens sur Gnosis et Ethereum qui ne fonctionnait que pour le Wallet 3 </p>
				<p>- Correction de l'anomalie absence de la valeur du Yield quand pas de Wallet sélectionné </p>
				<p>- Correction de plusieurs bugs divers </p>
				<br />
				<h1>Version 1.3.0</h1>
				<p>- ✨ Baptème de l'App : Pit'Swap ✨</p>
				<br />
				<h1>Version 1.2.1</h1>
				<p>- Anomalie des tokens sur Gnosis et Ethereum (ne fonctionne que pour le Wallet 3)</p>
				<p>- Ajout des tries sur la Rent Start Date et le Nombre de Token</p>
				<p>- Suppression de la colonne "N°"</p>
				<p>- Correction de plusieurs bugs </p>
				<br />
				<h1>Version 1.2.0</h1>
				<p>- Fin de validation des tokens sur Gnosis et Ethereum </p>
				<p>- Possibilité de selectionner les 2 chaines pour avoir des stats globale</p>
				<p>- Amalioration de l'interopérabilité</p>
				<p>- Correction de plusieurs bugs </p>
				<br />
				<h1>Version 1.1.1</h1>
				<p>- Ajout des statistiques par wallet et totale </p>
				<p>- Ajout de la blockchain Ethereum (mais ç ne fonctionne pas encore ...)</p>
				<p>- Ajout des statistiques des rents reçue via le site de @ehpst (wallet1 & wallet2 max)</p>
				<p>- Ajout des statistiques de chaque propriété via le site de @ehpst</p>
				<p>- Ajout de la gestion des Coins CHSB et WETH dans les offres SwapCat (WETH non testé, ratio Coin/USD à intégrer !!!)</p>
				<p>- Correction de plusieurs bugs </p>
				<br />
				<h1>Version 1.1.0</h1>
				<p>- Ajout de la saisie de 1 à 3 wallet pour suivre vos token au global et par wallet </p>
				<p>- Ajout du filtrage sur les tokens présents dans vos wallets (With Amount) </p>
				<p>- Ajout du filtrage sur les tokens non présents dans vos wallets (Without Amount) </p>
				<p>- Correction de plusieurs bugs (merci @Draazzzz) </p>
				<br />
				<h1>Version 1.0.5</h1>
				<p>- Ajout de la page Dashboard pour lister les token RealT (trié par rent start date)</p>
				<br />
				<h1>Version 1.0.4</h1>
				<p>- Ajout de la page pour lister les offres d'achat</p>
				<p>- Sauvegarde des réglages du bandeaux de recherche pour ne pas les modifier après refresh de la page</p>
				<br />
				<h1>Version 1.0.3</h1>
				<p>- Ajout de l'adresse du vendeur</p>
				<p>en saisissant quelques lettre de ton adresse tu filtreras tes offres &#128512;</p>
				<br />
				<h1>Version 1.0.2</h1>
				<p>- Ajout du Filtrage sur le maximum de Yield RealT</p>
				<p>- Evolution, la sélection du nombre max d'offre correspond à toutes les offres</p>
				<p>- Evolution, ajout d'un champ texte pour filtrer les tokens par leur nom</p>
				<p>- Affichage du Yield avec 2 chiffres après la virgule</p>
				<p>- Reorganisation du bandeau de recherche</p>
				<p>- Correction bug de Navigation et d'affichage</p>
				<br />
				<h1>Version 1.0.1</h1>
				<p>- Ajout des images représentant les tokens</p>
				<p>- Correction bug de Navigation</p>
				<br />
				<h1>Version 1.0</h1>
				<p>- Relooking du site</p>
				<p>- Ajout de critère pour mieux filtrer les offres</p>
			</div>
			<br />
		</div>
	);
};

export default About;
