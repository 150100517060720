import React, { useEffect, useState } from "react";
import SwapcatOffer from "./SwapcatOffer";
import SwapcatTitle from "./SwapcatTitle";

const APICOINGECKO_BORG = "https://api.coingecko.com/api/v3/coins/swissborg";
const APICOINGECKO_ETH = "https://api.coingecko.com/api/v3/coins/ethereum";
const APICOINGECKO_XDAI = "https://api.coingecko.com/api/v3/coins/xdai";
const APICOINGECKO_MAI = "https://api.coingecko.com/api/v3/coins/mimatic";
const APICOINGECKO_USDT = "https://api.coingecko.com/api/v3/coins/tether";

const APIURL_WHITELIST = `https://ehpst.duckdns.org/realt_rent_tracker/api/whitelist/`;

const APIURL_PITSWAP = "https://pitswap-api.herokuapp.com/api/";

const COINS_LIST = [
	{ address: "0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83", token: "USDC", decimal: 6, imgToken: "/images/usdc.png" },
	{ address: "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d", token: "WXDAI", decimal: 18, imgToken: "/images/xdai.png" },
	{ address: "0x7349C9eaA538e118725a6130e0f8341509b9f8A0", token: "ARMM", decimal: 18, imgToken: "/images/armm.png" },
	// { address: "0x7349C9eaA538e118725a6130e0f8341509b9f8A0", token: "aRMM", decimal: 18, imgToken: "/images/xdai.png" },
	{ address: "0x6eefFC9Faa02700a7f0DcC363736CdD71238CAED", token: "BORG", decimal: 18, imgToken: "/images/chsb.png" },
	{ address: "0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1", token: "WETH", decimal: 18, imgToken: "/images/eth.png" },
	{ address: "0x3F56e0c36d275367b8C502090EDF38289b3dEa0d", token: "MAI", decimal: 18, imgToken: "/images/mai.png" },
	{ address: "0x4ECaBa5870353805a9F068101A40E0f32ed605C6", token: "USDT", decimal: 6, imgToken: "/images/usdt.png" },
];

const TIMEOUT_REFRESH_PRIX = 5 * 60 * 1000; // Timer pour le refresh des prix en millisecondes
const TIMEOUT_REFRESH = 5 * 60 * 1000; // Timer pour le refresh des offres en millisecondes
var imgFetching = "/images/loading.gif";

// Hook
function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
}

const SwapcatList = ({ buy_offers = false, sell_offers = false }) => {
	const [data, setData] = useState([]); // This state will store the parsed data
	const [tokens, setTokens] = useState([]); // This state will store the parsed data
	// const [data_sm, setDataSM] = useState([]); // This state will store the parsed data
	const [data_wl1, setDataWL1] = useState([]); // This state will store the RealToken parsed data
	const [ethValue, setETHValue] = useState([]); // This state will store the parsed data
	const [chsbValue, setCHSBValue] = useState([]); // This state will store the parsed data
	// eslint-disable-next-line no-unused-vars
	const [xdaiValue, setXDaiValue] = useState([]); // This state will store the parsed data
	const [maiValue, setMaiValue] = useState([]); // This state will store the parsed data
	const [usdtValue, setUsdtValue] = useState([]); // This state will store the parsed data
	const [rangeValue, setRangeValue] = useState(parseFloat(JSON.parse(localStorage.getItem("rangeValue"))) || 100.0);
	const [amountValue, setAmountValue] = useState(parseFloat(JSON.parse(localStorage.getItem("amountValue"))) || 0.25);
	const [minRTYieldValue, setMinRTYieldValue] = useState(parseFloat(JSON.parse(localStorage.getItem("minRTYieldValue"))) || 9.5);
	const [minDiffPriceValue, setMinDiffPriceValue] = useState(parseFloat(JSON.parse(localStorage.getItem("minDiffPriceValue"))) || 20.0);
	const [maxDiffPriceValue, setMaxDiffPriceValue] = useState(parseFloat(JSON.parse(localStorage.getItem("maxDiffPriceValue"))) || 20.0);
	const [selectedRadio, setSelectedRadio] = useState(JSON.parse(localStorage.getItem("selectedRadio")) || "");
	const [checkboxRMM, setCheckboxRMM] = useState(JSON.parse(localStorage.getItem("setCheckboxRMM")) || false);
	const [checkboxVendeur, setCheckboxVendeur] = useState(JSON.parse(localStorage.getItem("setCheckboxVendeur")) || false);
	const [checkboxWhitelist, setCheckboxWhitelist] = useState(JSON.parse(localStorage.getItem("setCheckboxWhitelist_swapcat")) || false);
	const [checkboxAvgYield, setCheckboxAvgYield] = useState(JSON.parse(localStorage.getItem("setCheckboxAvgYield_swapcat")) || false);
	const [selectedTextSearch, setSelectedTextSearch] = useState(JSON.parse(localStorage.getItem("selectedTextSearch")) || "");
	const [selectedAccount1, setSelectedAccount1] = useState(JSON.parse(localStorage.getItem("selectedAccount1_swapcat")) || "");
	const radios = ["USDC", "WXDAI", "ARMM", "BORG", "WETH", "MAI", "USDT"];
	const radiosRMM = ["RMM"];
	const radiosVendeur = ["@ Vendeur"];
	const [is_fetching, setIs_fetching] = useState(false);

	const [smallScreen, setsmallScreen] = useState(false);
	const size = useWindowSize();
	useEffect(() => {
		if (size.width < 1200) {
			setsmallScreen(true);
		} else {
			setsmallScreen(false);
		}
	}, [size.width]);

	// ----------------------------------------------
	// ---- Timer pour mise à jour des offres    ----
	// ----------------------------------------------
	const [state, setState] = React.useState(false);
	const counter = React.useRef(0);
	React.useEffect(() => {
		counter.current += 1;
		const timer = setTimeout(() => setState({ num: counter.current }), TIMEOUT_REFRESH);
		return () => clearTimeout(timer);
	}, [state]);
	// ----------------------------------------------
	// ---- Timer pour mise à jour des prix      ----
	// ----------------------------------------------
	const [statePrix, setStatePrix] = React.useState({ num: 0 });
	const counterPrix = React.useRef(0);
	React.useEffect(() => {
		counterPrix.current += 1;
		const timer = setTimeout(() => setStatePrix({ num: statePrix.current }), TIMEOUT_REFRESH_PRIX);
		return () => clearTimeout(timer);
	}, [statePrix]);

	// ----------------------------------------------
	// ---- Reccupération de la liste des offres ----
	// ----------------------------------------------
	useEffect(() => {
		if (selectedAccount1) {
			fetch(APIURL_PITSWAP + "secondary_market_01/" + selectedAccount1.toLowerCase()).then((reponse) => {
				if (reponse.ok) {
					if (reponse.ok) reponse.json().then((body) => setData(body));
				}
			});
		} else {
			fetch(APIURL_PITSWAP + "secondary_market_01").then((reponse) => {
				if (reponse.ok) {
					if (reponse.ok) reponse.json().then((body) => setData(body));
				}
			});
		}
	}, [state]);

	useEffect(() => {
		setIs_fetching(true);
		fetch(APIURL_PITSWAP + "realTokens_xdai_rmm").then((reponse) => {
			if (reponse.ok) {
				setIs_fetching(false);
				if (reponse.ok) reponse.json().then((body) => setTokens(body));
			}
		});
		if (selectedAccount1) {
			fetch(APIURL_PITSWAP + "secondary_market_01/" + selectedAccount1.toLowerCase()).then((reponse) => {
				if (reponse.ok) {
					if (reponse.ok) reponse.json().then((body) => setData(body));
				}
			});
		} else {
			fetch(APIURL_PITSWAP + "secondary_market_01").then((reponse) => {
				if (reponse.ok) {
					if (reponse.ok) reponse.json().then((body) => setData(body));
				}
			});
		}
	}, []);
	// --------------------------------------------
	// ---- Reccupération de la liste des prix ----
	// --------------------------------------------
	useEffect(() => {
		// console.log("APICOINGECKO_ETH", APICOINGECKO_ETH, ethValue, chsbValue, xdaiValue);
		fetch(APICOINGECKO_ETH).then((reponse) => {
			// console.log("reponse", reponse.json());
			if (reponse.ok)
				reponse.json().then((body) => {
					setETHValue(body.market_data.current_price.usd);
					// console.log("ethValue", body.market_data.current_price.usd);
				});
		});
	}, [statePrix]);
	useEffect(() => {
		fetch(APICOINGECKO_BORG).then((reponse) => {
			// console.log("reponse", reponse.json());
			if (reponse.ok) reponse.json().then((body) => setCHSBValue(body.market_data.current_price.usd));
		});
	}, [statePrix]);
	useEffect(() => {
		fetch(APICOINGECKO_XDAI).then((reponse) => {
			// console.log("reponse", reponse.json());
			if (reponse.ok) reponse.json().then((body) => setXDaiValue(body.market_data.current_price.usd));
		});
	}, [statePrix]);
	useEffect(() => {
		fetch(APICOINGECKO_MAI).then((reponse) => {
			// console.log("reponse", reponse.json());
			if (reponse.ok) reponse.json().then((body) => setMaiValue(body.market_data.current_price.usd*1.5));
		});
	}, [statePrix]);
	useEffect(() => {
		fetch(APICOINGECKO_USDT).then((reponse) => {
			// console.log("reponse", reponse.json());
			if (reponse.ok) reponse.json().then((body) => setUsdtValue(body.market_data.current_price.usd));
		});
	}, [statePrix]);
	// ----------------------------------
	// ---- Request on WhiteList API ----
	// ----------------------------------
	useEffect(() => {
		if (selectedAccount1) {
			fetch(APIURL_WHITELIST + selectedAccount1).then((reponse) => {
				// console.log("APIURL_WHITELIST", reponse.json());
				if (reponse.ok) {
					if (reponse.ok) reponse.json().then((body) => setDataWL1(body));
				}
			});
		}
	}, [selectedAccount1]);

	// ---------------------------------------------
	// ---- Sauvegarde des variable de la pages ----
	// ---------------------------------------------
	useEffect(() => {
		localStorage.setItem("rangeValue", JSON.stringify(rangeValue + 0.0000001));
	}, [rangeValue]);
	useEffect(() => {
		localStorage.setItem("amountValue", JSON.stringify(amountValue + 0.0000001));
	}, [amountValue]);
	useEffect(() => {
		localStorage.setItem("minRTYieldValue", JSON.stringify(minRTYieldValue + 0.0000001));
		// console.log("minRTYieldValue", JSON.stringify(minRTYieldValue+0.0000001))
	}, [minRTYieldValue]);
	useEffect(() => {
		localStorage.setItem("minDiffPriceValue", JSON.stringify(minDiffPriceValue + 0.0000001));
	}, [minDiffPriceValue]);
	useEffect(() => {
		localStorage.setItem("maxDiffPriceValue", JSON.stringify(maxDiffPriceValue + 0.0000001));
	}, [maxDiffPriceValue]);
	useEffect(() => {
		localStorage.setItem("selectedRadio", JSON.stringify(selectedRadio));
	}, [selectedRadio]);
	useEffect(() => {
		localStorage.setItem("setCheckboxRMM", JSON.stringify(checkboxRMM));
	}, [checkboxRMM]);
	useEffect(() => {
		localStorage.setItem("setCheckboxVendeur", JSON.stringify(checkboxVendeur));
	}, [checkboxVendeur]);
	useEffect(() => {
		localStorage.setItem("setCheckboxWhitelist_swapcat", JSON.stringify(checkboxWhitelist));
	}, [checkboxWhitelist]);
	useEffect(() => {
		localStorage.setItem("setCheckboxAvgYield_swapcat", JSON.stringify(checkboxAvgYield));
	}, [checkboxAvgYield]);
	useEffect(() => {
		localStorage.setItem("selectedAccount1_swapcat", JSON.stringify(selectedAccount1.toLowerCase().replace(" ", "")));
	}, [selectedAccount1]);
	useEffect(() => {
		localStorage.setItem("selectedTextSearch", JSON.stringify(selectedTextSearch));
	}, [selectedTextSearch]);

	// ------------------------------------------
	// ---- Fonctions des params de la pages ----
	// ------------------------------------------
	function setCheckbox(checked, name) {
		// console.log(name, checked);
		if (name === "checkboxRMM") setCheckboxRMM(checked);
		if (name === "checkboxWhitelist") setCheckboxWhitelist(checked);
		if (name === "checkboxVendeur") setCheckboxVendeur(checked);
		if (name === "checkboxAvgYield") setCheckboxAvgYield(checked);
	}
	function setSelectedAccount1_onChange(name) {
		setSelectedAccount1(name.replace(" ", "").toLowerCase());
		if (name === "") reload_page();
	}
	function reload_page() {
		var reload = function () {
				window.location.reload(false);
			},
			timeout = window.setTimeout(reload, 3000);
		return function () {
			window.clearTimeout(timeout);
			timeout = window.setTimeout(reload, 2000);
		};
	}

	var dataList = data;
	// console.log("dataList", dataList);

	// ---------------------------------------------------------------------------------------------
	// ---- Ajout des données du token à vendre ou acheter ...                                  ----
	// ---------------------------------------------------------------------------------------------
	if (dataList.length > 0) {
		for (var i = 0; i < dataList.length; i++) {
			dataList[i].realtShortName = "noLink";
			dataList[i].realtFullName = "noLink";
			dataList[i].realtMarketplaceLink = "noLink";
			// console.log("realtShortName:", dataList[i].realtShortName);
			if (tokens.length > 0) {
				if (dataList[i].token_to_sell && dataList[i].token_to_pay) {
					const token = tokens.filter((a) => a.uuid === dataList[i].token_to_sell);
					if (token.length > 0) {
						// console.log(token[0],dataList[i]);
						dataList[i].is_sell = "True";
						dataList[i].is_buy = "False";
						dataList[i].realtShortName = token[0].shortName;
						dataList[i].realtFullName = token[0].fullName;
						dataList[i].realtMarketplaceLink = token[0].marketplaceLink;
						// console.log(dataList[i].realtFullName, token[0]);
						dataList[i].realtTokenPrice = token[0].tokenPrice;
						dataList[i].is_rmm = "False";
						if (token[0].is_rmm) dataList[i].is_rmm = "True";
						dataList[i].realtNetRentYearPerToken = token[0].netRentYearPerToken;
						if (checkboxAvgYield) dataList[i].realtNetRentYearPerToken = (token[0].avg_yield * token[0].tokenPrice) / 100;
						const coinName = COINS_LIST.filter((a) => a.address.toLowerCase() === dataList[i].token_to_pay);
						if (coinName.length > 0) {
							dataList[i].coinShortName = coinName[0].token;
						}
						// console.log("data[i]", dataList[i].token_to_sell, token, coinName);
					}
				}
				if (dataList[i].token_to_buy && dataList[i].token_to_pay) {
					const token = tokens.filter((a) => a.uuid === dataList[i].token_to_buy);
					if (token.length > 0) {
						// console.log(token[0],dataList[i]);
						dataList[i].is_sell = "False";
						dataList[i].is_buy = "True";
						dataList[i].realtShortName = token[0].shortName;
						dataList[i].realtFullName = token[0].fullName;
						dataList[i].realtMarketplaceLink = token[0].marketplaceLink;
						// if (!token[0].realtMarketplaceLink) console.log(dataList[i].realtFullName, token[0].fullName, token[0]);
						dataList[i].realtTokenPrice = token[0].tokenPrice;
						dataList[i].is_rmm = "False";
						if (token[0].is_rmm) dataList[i].is_rmm = "True";
						dataList[i].realtNetRentYearPerToken = token[0].netRentYearPerToken;
						if (checkboxAvgYield) dataList[i].realtNetRentYearPerToken = (token[0].avg_yield * token[0].tokenPrice) / 100;
						const coinName = COINS_LIST.filter((a) => a.address.toLowerCase() === dataList[i].token_to_pay);
						if (coinName.length > 0) {
							dataList[i].coinShortName = coinName[0].token;
						}
						// console.log("data[i]", dataList[i].token_to_pay, token, coinName);
					}
				}
			}
		}

		for (i = 0; i < dataList.length; i++) {
			if (!dataList[i].realtShortName)
				console.log(
					i,
					"realtShortName:",
					dataList[i].realtShortName,
					"realtFullName:",
					dataList[i].realtFullName,
					"realtMarketplaceLink:",
					dataList[i].realtMarketplaceLink
				);

			if (!dataList[i].realtFullName)
				console.log(
					i,
					"realtShortName:",
					dataList[i].realtShortName,
					"realtFullName:",
					dataList[i].realtFullName,
					"realtMarketplaceLink:",
					dataList[i].realtMarketplaceLink
				);

			if (!dataList[i].realtMarketplaceLink)
				console.log(
					i,
					"realtShortName:",
					dataList[i].realtShortName,
					"realtFullName:",
					dataList[i].realtFullName,
					"realtMarketplaceLink:",
					dataList[i].realtMarketplaceLink,
					dataList[i].contract,
					dataList[i].is_buy
				);
		}
	}
	// console.log("data[i]", dataList[0]);
	// ---------------------------------------------------------------------------------------------
	// ---- Vérification des Token whitelisté avec les wallets selectionnés ...                 ----
	// ---------------------------------------------------------------------------------------------
	if (dataList.length > 0) {
		for (i = 0; i < dataList.length; i++) {
			dataList[i].is_whitelist_present = false;
			if (data_wl1.length > 0) dataList[i].is_whitelist_present = true;

			dataList[i].is_whitelist = false;
			for (var j = 0; j < data_wl1.length; j++) {
				// console.log("data[i]", data[i], data_wl1[j]);
				if (dataList[i].token_to_sell) {
					if (dataList[i].token_to_sell.toLowerCase() === data_wl1[j].token.toLowerCase()) {
						dataList[i].is_whitelist = true;
					}
				}
				if (dataList[i].token_to_buy) {
					if (dataList[i].token_to_buy.toLowerCase() === data_wl1[j].token.toLowerCase()) {
						dataList[i].is_whitelist = true;
					}
				}
			}
		}
	}
	// ---------------------------------------------------------------------------------------------
	// ---- Conversion du prix des Non Stable Coin en dollars ...                               ----
	// ---------------------------------------------------------------------------------------------
	for (i = 0; i < COINS_LIST.length; i++) {
		COINS_LIST[i].to_usd = 1.0;
		if (COINS_LIST[i].token === "WETH") COINS_LIST[i].to_usd = ethValue;
		if (COINS_LIST[i].token === "BORG") COINS_LIST[i].to_usd = chsbValue;
		if (COINS_LIST[i].token === "MAI") COINS_LIST[i].to_usd = maiValue;
		if (COINS_LIST[i].token === "USDT") COINS_LIST[i].to_usd = usdtValue;
		// if (Coin_list[i].token === "WXDAI") Coin_list[i].to_usd = xdaiValue;
	}
	// console.log('data[i]',data[0]);
	if (dataList.length > 0) {
		for (i = 0; i < dataList.length; i++) {
			dataList[i].realtTokenPrice_usd = data[i].realtTokenPrice;
			dataList[i].imgToken = "/images/btc.png";
			for (j = 0; j < COINS_LIST.length; j++) {
				if (dataList[i].coinShortName === COINS_LIST[j].token) {
					dataList[i].token_value_usd = dataList[i].token_value * COINS_LIST[j].to_usd;
					dataList[i].imgToken = COINS_LIST[j].imgToken;
				}
			}
		}
	}
	// ---------------------------------------------------------------------------------------------
	// ---- Réorganisation de la liste tes offres en fonctions des paramètres de la page ...    ----
	// ---------------------------------------------------------------------------------------------
	if (dataList.length > 0) {
		// console.log("dataList.length start:", dataList.length);
		var textFilter_activate = true;
		for (i = 0; i < dataList.length; i++) {
			if (!dataList[i].realtShortName || !dataList[i].realtFullName || !dataList[i].holder_address) {
				textFilter_activate = false;
				console.log(
					"selectedTextSearch:",
					selectedTextSearch,
					i,
					dataList[i].realtShortName,
					dataList[i].realtFullName,
					dataList[i].holder_address,
					dataList[i]
				);
			}
		}
		// console.log("dataList.length 1:", dataList.length, textFilter_activate);

		if (selectedTextSearch && textFilter_activate) {
			// console.log("selectedTextSearch fredo:", selectedTextSearch, dataList);
			dataList = dataList.filter(
				(a) =>
					a.realtShortName.toLowerCase().includes(selectedTextSearch.toLowerCase()) ||
					a.realtFullName.toLowerCase().includes(selectedTextSearch.toLowerCase()) ||
					a.holder_address.toLowerCase().includes(selectedTextSearch.toLowerCase())
			);
		}
		// console.log("dataList.length 2:", dataList.length);

		if (checkboxRMM) dataList = dataList.filter((a) => a.is_rmm === "True");
		// console.log("dataList.length 3:", dataList.length);

		if (selectedRadio) dataList = dataList.filter((a) => (a.coinShortName ? a.coinShortName.includes(selectedRadio) : false));
		// console.log("dataList.length 4:", dataList.length);

		if (minRTYieldValue >= 0.1)
			dataList = dataList.filter((a) => parseFloat(a.realtNetRentYearPerToken / a.realtTokenPrice) >= parseFloat(minRTYieldValue / 100));
		// console.log("dataList.length 5:", dataList.length, "minRTYieldValue:", minRTYieldValue);

		if (buy_offers) {
			dataList = dataList.filter((a) => a.is_buy === "True");
			// console.log("dataList.length buy-1:", dataList.length);

			if (minDiffPriceValue > -30) {
				dataList = dataList.filter((a) => parseFloat(a.token_value_usd / a.realtTokenPrice) >= parseFloat((100 + minDiffPriceValue) / 100));
			}
			console.log("dataList.length buy-2:", dataList.length, "minDiffPriceValue:", minDiffPriceValue);
			// if (dataList.length > 0) console.log("buy dataList", dataList);

			if (parseFloat(amountValue) > 0.001) dataList = dataList.filter((a) => parseFloat(a.token_amount) - parseFloat(amountValue) >= 0);
			// console.log("dataList.length buy-3:", dataList.length, "amountValue", amountValue);

			dataList = dataList.sort((a, b) => parseFloat(b.token_value_usd / b.realtTokenPrice) - parseFloat(a.token_value_usd / a.realtTokenPrice));
		}
		if (sell_offers) {
			dataList = dataList.filter((a) => a.is_sell === "True");
			// console.log("dataList.length sell-1:", dataList.length);

			dataList = dataList.filter((a) => (checkboxWhitelist ? a.is_whitelist : true));
			// console.log("dataList.length sell-2:", dataList.length);

			if (maxDiffPriceValue < 30) {
				dataList = dataList.filter((a) => parseFloat(a.token_value_usd / a.realtTokenPrice - 1) <= parseFloat(maxDiffPriceValue / 100));
			}
			// console.log("dataList.length sell-3:", dataList.length, "maxDiffPriceValue:", maxDiffPriceValue);

			// if (dataList.length > 0) console.log("sell dataList", dataList[0].token_amount,parseFloat(amountValue));
			// if (dataList.length > 0) console.log("sell dataList", dataList);
			if (parseFloat(amountValue) > 0.001) dataList = dataList.filter((a) => parseFloat(a.token_amount) - parseFloat(amountValue) >= 0);
			// console.log("dataList.length sell-4:", dataList.length);

			// console.log("----------------------------------------------");
			var sort_activate = true;
			for (i = 0; i < dataList.length; i++) {
				if (!dataList[i].realtNetRentYearPerToken && !dataList[i].token_value_usd) {
					sort_activate = false;
					console.log("Pb dataList", i, dataList[i], dataList[i].realtNetRentYearPerToken, dataList[i].token_value_usd);
				}
			}
			// if (!sort_activate) console.log("Pb avec les Token_to_pay", dataList);

			// if (sort_activate) {
			dataList = dataList.sort(
				(a, b) =>
					parseFloat(b.token_value_usd ? b.realtNetRentYearPerToken / b.token_value_usd : 0) -
					parseFloat(a.token_value_usd ? a.realtNetRentYearPerToken / a.token_value_usd : 0)
			);
			// }
		}
	}

	dataList = dataList.slice(0, rangeValue >= 250.0 ? dataList.length : rangeValue);

	if (size.width < 1200 && !smallScreen) setsmallScreen(true);

	// if (dataList.length > 0) console.log("sell dataList", dataList);

	return (
		<div className='swapcat'>
			{is_fetching && <img height='20px' src={imgFetching} alt={"Loading of RealTokens"} />}
			{selectedRadio && <button onClick={() => setSelectedRadio("")}>Reset Coin filter</button>}
			<div className='radio-container'>
				<ul>
					<div>
						<li id='spinner'>
							<input
								type='range'
								id='rangeValue'
								min='10'
								max='250'
								step='10'
								// defaultValue='100'
								value={rangeValue}
								onChange={(e) => setRangeValue(parseInt(e.target.value))}
							/>
							<label htmlFor='rangeValue'>{parseFloat(rangeValue) >= 250.0 ? "All offers" : parseInt(rangeValue) + " offers"}</label>
						</li>
						<li id='spinner'>
							<input
								type='range'
								id='amountValue'
								min='0.0'
								max='1.0'
								step='0.01'
								// defaultValue='0.25'
								value={amountValue}
								onChange={(e) => setAmountValue(parseFloat(e.target.value))}
							/>
							<label htmlFor='amountValue'>
								{parseFloat(amountValue) < 0.01 ? "All offers" : parseFloat(amountValue).toFixed(2) + " Min amount"}{" "}
							</label>
						</li>
						{smallScreen && (
							<li id='spinner'>
								<input
									type='range'
									id='minRTYieldValue'
									min='0'
									max='15'
									step='0.1'
									// defaultValue='9.5'
									value={minRTYieldValue}
									onChange={(e) => setMinRTYieldValue(parseFloat(e.target.value))}
								/>
								<label htmlFor='minRTYieldValue'>{parseFloat(minRTYieldValue).toFixed(1) + "% Min RealT Yield"}</label>
							</li>
						)}
						{smallScreen && (
							<div>
								{sell_offers && (
									<li id='spinner'>
										<input
											type='range'
											id='maxDiffPriceValue'
											min='-5'
											max='30'
											step='0.1'
											// defaultValue='15'
											value={maxDiffPriceValue}
											onChange={(e) => setMaxDiffPriceValue(parseFloat(e.target.value))}
										/>
										<label htmlFor='maxDiffPriceValue'>
											{parseFloat(maxDiffPriceValue) >= 30.0 ? "All offers" : maxDiffPriceValue.toFixed(1) + "% Max Diff Price"}
										</label>
									</li>
								)}
								{buy_offers && (
									<li id='spinner'>
										<input
											type='range'
											id='minDiffPriceValue'
											min='-30'
											max='30'
											step='0.1'
											// defaultValue='15'
											value={minDiffPriceValue}
											onChange={(e) => setMinDiffPriceValue(parseFloat(e.target.value))}
										/>
										<label htmlFor='minDiffPriceValue'>
											{parseFloat(minDiffPriceValue) < -29.99 ? "All offers" : minDiffPriceValue.toFixed(1) + "% Min Diff Price"}
										</label>
									</li>
								)}
							</div>
						)}
					</div>
					{!smallScreen && (
						<div>
							<li id='spinner'>
								<input
									type='range'
									id='minRTYieldValue'
									min='0'
									max='15'
									step='0.1'
									// defaultValue='9.5'
									value={minRTYieldValue}
									onChange={(e) => setMinRTYieldValue(parseFloat(e.target.value))}
								/>
								<label htmlFor='minRTYieldValue'>{parseFloat(minRTYieldValue).toFixed(1) + "% Min RealT Yield"}</label>
							</li>
							{sell_offers && (
								<li id='spinner'>
									<input
										type='range'
										id='maxDiffPriceValue'
										min='-5'
										max='30'
										step='0.1'
										// defaultValue='15'
										value={maxDiffPriceValue}
										onChange={(e) => setMaxDiffPriceValue(parseFloat(e.target.value))}
									/>
									<label htmlFor='maxDiffPriceValue'>
										{parseFloat(maxDiffPriceValue) >= 30.0 ? "All offers" : maxDiffPriceValue.toFixed(1) + "% Max Diff Price"}
									</label>
								</li>
							)}
							{buy_offers && (
								<li id='spinner'>
									<input
										type='range'
										id='minDiffPriceValue'
										min='-30'
										max='30'
										step='0.1'
										// defaultValue='15'
										value={minDiffPriceValue}
										onChange={(e) => setMinDiffPriceValue(parseFloat(e.target.value))}
									/>
									<label htmlFor='minDiffPriceValue'>
										{parseFloat(minDiffPriceValue) <= -29.99 ? "All offers" : minDiffPriceValue.toFixed(1) + "% Min Diff Price"}
									</label>
								</li>
							)}
						</div>
					)}
					<div>
						{radios.map((radio) => (
							<li id='radio'>
								<input type='radio' id={radio} name='radio' checked={radio === selectedRadio} onChange={(e) => setSelectedRadio(e.target.id)} />
								<label htmlFor={radio}>{radio}</label>
							</li>
						))}
					</div>
					<div>
						{/* {sell_offers && ( */}
						<li id='checkbox'>
							<input
								type='text-adr'
								name='selectedAccount1'
								value={selectedAccount1.toLowerCase()}
								placeholder='Whitelist Wallet ?'
								onFocus={(e) => e.target.select()}
								onChange={(e) => setSelectedAccount1_onChange(e.target.value)}
							/>
						</li>
						{/* )} */}
						{radiosRMM.map((radio) => (
							<li id='radio'>
								<input
									type='checkbox'
									id='checkboxRMM'
									name='checkboxRMM'
									checked={checkboxRMM}
									onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
								/>
								<label htmlFor='checkboxRMM'>RMM</label>
							</li>
						))}
						{radiosVendeur.map((radio) => (
							<li id='radio'>
								<input
									type='checkbox'
									id='checkboxVendeur'
									name='checkboxVendeur'
									checked={checkboxVendeur}
									onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
								/>
								<label htmlFor='checkboxVendeur'>{buy_offers ? "@ Buyer" : "@ Seller"}</label>
							</li>
						))}
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxWhitelist'
								name='checkboxWhitelist'
								checked={checkboxWhitelist}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxWhitelist'>Whitelist</label>
						</li>
						<li id='radio'>
							<input
								type='checkbox'
								id='checkboxAvgYield'
								name='checkboxAvgYield'
								checked={checkboxAvgYield}
								onChange={(e) => setCheckbox(e.target.checked, e.target.name)}
							/>
							<label htmlFor='checkboxAvgYield'>AVG Yield</label>
						</li>
					</div>
					<div>
						<li id='checkbox'>
							<label htmlFor='selectedTextSearch'>Filter by Token name or</label>
						</li>
						<li id='checkbox'>
							<label htmlFor='selectedTextSearch'>Filter by Holder address :</label>
						</li>
						<li id='text'>
							{/* <label >Recherche</label> */}
							<input
								type='search'
								name='selectedTextSearch'
								value={selectedTextSearch}
								placeholder='Name or address ?'
								onFocus={(e) => e.target.select()}
								onChange={(e) => setSelectedTextSearch(e.target.value)}
							/>
						</li>
					</div>
				</ul>
			</div>
			<div className='table-container'>
				<table>
					<SwapcatTitle
						vendeur={checkboxVendeur}
						sell_offers={sell_offers}
						iscol_my_offer={data.map((e) => (e.holder_address === selectedAccount1 ? true : false)).length > 0 ? true : false}
					/>
					{dataList.map((value, index) => (
						<tbody>
							<SwapcatOffer
								offer={value}
								num={index + 1}
								vendeur={checkboxVendeur}
								sell_offers={sell_offers}
								iscol_my_offer={data.map((e) => (e.holder_address === selectedAccount1 ? true : false)).length > 0 ? true : false}
								my_offer={value.holder_address.toLowerCase() === selectedAccount1.toLowerCase() ? true : false}
							/>
						</tbody>
					))}
				</table>
			</div>
		</div>
	);
};

export default SwapcatList;
