import React, { useEffect } from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import SwapTokenList from "../components/swapToken/SwapTokenList";

const TITLE = "Pit'Swap - Swap Tokens";

const Swap = () => {

  useEffect(() => {
    document.title = TITLE;
 }, []);
 
  // this.Home.push();
  return (
    <div>
      <Logo web3={false} />
      <Navigation  />
      <SwapTokenList />
    </div>
  );
};

export default Swap;
